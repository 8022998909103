import { useEffect, useState } from 'react';
import { postTicket, getTicket, getAlluser } from '../../../apihandler/apihandler';

import Card from '@mui/material/Card';
import {
    Button,
    InputLabel, FormControl, Select, TextField, MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';


export default function Newticket() {

    const navigate = useNavigate()

    const [subject, setSubject] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [status, setStatus] = useState("")
    const [priority, setPriority] = useState("")
    const [type, setType] = useState("")
    const [company, setCompany] = useState([]);
    const [companydata, setcompanydata] = useState('');

    const handleSubjectChange = (event) => {
        setSubject(event.target.value)
    }
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }

    const handleImageChange = (event) => {
        setImage(event.target.value)
    }


    const handleStatusChange = (event) => {
        setStatus(event.target.value)
    }

    const handlePriorityChange = (event) => {
        setPriority(event.target.value)
    }

    const handleTypeChange = (event) => {
        setType(event.target.value)
    }

    const handleCompanyChange = (event) => {
        setcompanydata(event.target.value)
    }

    const handleCreateTicket = async () => {
        const Value = {
            subject,
            description,
            added_by: 'superadmin',
            image,
            status,
            priority,
            type,
            customer_id:companydata
        };

        try {
            const response = await postTicket(Value);
            console.log('Ticket created:', response.data);
            navigate("../support")

        } catch (error) {
            console.error('Error creating ticket:', error);
        }
    };


    useEffect(() => {
        getallCompany();

    }, []);


    const getallCompany = async () => {

        try {
            const respnse = await getAlluser();
            if (respnse.status === 200) {
                setCompany(respnse.data.fetchUser);
            }
            console.log(respnse, 'response----->')

        } catch (error) {

        }
    }




    return (
        <>
            <div className='px-3 mt-3 '>
                <h6>Tickets <i class="bi bi-arrow-right"></i> New tickets</h6>
                <Card className='px-4 mt-4 '>
                    <div className='ml-4'>
                        <div className='px-3 mt-2'>
                            <div className="row align-items-center">
                                <div className="col-sm-2">Subject*</div>
                                <div className="col-sm-10">
                                    <TextField
                                        fullWidth
                                        placeholder='Enter Title Here'
                                        value={subject}
                                        onChange={handleSubjectChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='px-3 mt-2'>
                            <div className="row align-items-center">
                                <div className="col-sm-2">Description*</div>
                                <div className="col-sm-10">
                                    <textarea className="form-control" id="textAreaExample" rows="4" placeholder='Enter Description' value={description}
                                        onChange={handleDescriptionChange}></textarea>

                                </div>
                            </div>
                        </div>

                        <hr />



                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-5">
                                <div className='p-2 mt-2'>
                                    <input type='file' name='image' id='image' value={image}
                                        onChange={handleImageChange} />
                                </div>
                                <div className='p-2 mt-5'>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Priority"
                                            value={priority}
                                            onChange={handlePriorityChange}
                                        >
                                            <MenuItem value={"Critical"}>Critical</MenuItem>
                                            <MenuItem value={"Normal"}>Normal</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='p-2 mt-5'>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Company</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="company"
                                            value={companydata}
                                            onChange={handleCompanyChange}
                                        >
                                            {company.map((comp, index) => {
                                                return (
                                                    <MenuItem key={index} value={comp._id }>{comp.
                                                        organisation}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>


                            </div>

                            <div class="col-sm-5 ">
                                <div className='p-3'>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Customer"
                                            value={status}
                                            onChange={handleStatusChange}
                                        >
                                            <MenuItem value={"Open"}>Open</MenuItem>
                                            {/* <MenuItem value={"Re-Open"}>Re-Open</MenuItem>
                                            <MenuItem value={"Close"}>Close</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='p-3 mt-1'>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Customer"
                                            value={type}
                                            onChange={handleTypeChange}
                                        >
                                            <MenuItem value={"New Feature"}>New Feature</MenuItem>
                                            <MenuItem value={"Support"}>Support</MenuItem>
                                            <MenuItem value={"Setup"}>Setup</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                            </div>
                        </div>


                        <div style={{ justifyContent: "flex-end", display: "flex", paddingRight: "17px", marginBottom: "30px", marginTop: "10px" }}>
                            <Button variant="contained" style={{ backgroundColor: "#03565A", paddingRight: "10x" }} onClick={handleCreateTicket}>SAVE <span style={{ marginLeft: "10px" }}><i class="bi bi-arrow-right-circle" ></i></span> </Button>
                        </div>
                    </div>
                </Card>


            </div>





        </>
    )
}
