import React, { useState } from 'react';
import { Box, Button, Modal, Card, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ActiveAddPlanModal from './ActiveAddPlanModal';
import { postAllPlans ,getAllPlans} from '../../../apihandler/apihandler';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import SelectFeaturesModal from './SelectFeaturesModal';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    //   p: 4,
};

const AddPlanModaL = ({ open, handleClose, setOpenModal2 ,setUpdate,update}) => {

    const [formData, setFormData] = useState({
        planName: '',
        planDescription: '',
        benefits: '',
        categorizedBenefits: {},
        planPrice: '',
        planPeriod: '',
        users: 5,
        hasApprover: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleConfirm = (selectedItems, displayString) => {
        setFormData(prevState => ({
            ...prevState,
            benefits: displayString, // Display string in the input field
            categorizedBenefits: selectedItems // Keep the categorized structure for saving
        }));
    };




    const handleCreatePlan = () => {
        // Check if planName contains only letters
        if (!/^[a-zA-Z0-9\s]*$/.test(formData.planName)) {
            Swal.fire({
                icon: 'error',
                title: ' Error',
                text: 'Plan Name should only contain letters and numbers',
                confirmButtonColor: "#03565A"
            });
            return;
        }

        if (formData.planDescription.trim() === '') {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Plan Description cannot be empty',
                confirmButtonColor: "#03565A"
            });
            return;
        }

        // If planName is valid, proceed with creating the plan
        postAllPlans(formData)
            .then(res => {
                setFormData({ planDescription: "", planName: "", planPeriod: "", planPrice: "", users: "", hasApprover: false, benefits: '', });
                handleClose();
                setUpdate(!update)
                // setIsOpen(false);
                // getPlan();
                Swal.fire({
                    title: "Success!",
                    text: "Plan created successfully.",
                    icon: "success",
                    confirmButtonColor: "#03565A"
                });
            })
            .catch(error => {
                console.error('Error creating plan:', error);
                // setIsOpen(false);
                Swal.fire({
                    icon: 'error',
                    title: "error",
                    text: "Failed to create plan. Please try again later..",
                    confirmButtonColor: "#03565A"
                });
            });
    };


    const handleClosemodal = () => {
        setFormData({ planDescription: "", planName: "", planPeriod: "", planPrice: "", users: "", hasApprover: false, benefits: '' });
        setOpenModal2(false)
    }


    const [showNestedModal, setShowNestedModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleNestedModalClose = () => {
        setShowNestedModal(false);
    };

    const handlePlanNameClick = () => {
        setShowNestedModal(true);
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <div>
                    <div className='d-flex justify-content-between p-3 rounded' style={{ backgroundColor: "#F1F2F5" }}>
                        <p className='mt-0 mb-0 font-size-20 custom-font-600 font-sans'>Add Plan</p>
                        <p className='mt-0 mb-0 font-size-16 custom-font-600 font-sans' onClick={handleClosemodal} style={{ cursor: 'pointer' }}>Close</p>
                    </div>
                    <div className='p-3'>
                        <div className='d-flex flex-column mb-3'>
                            <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-dark font-sans'>Plan Name:</h3>
                            <input className='new-textinput p-2 mt-2 w-100' type="text" name="planName"
                                value={formData.planName} onChange={handleChange} />
                            {/*  */}
                            {/* <ActiveAddPlanModal openModal={openModal} handleCloseModal={handleCloseModal} />  */}
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-dark font-sans'>Description:</h3>
                            <input className='new-textinput p-2 mt-2 w-100' type="text" name="planDescription"
                                value={formData.planDescription} onChange={handleChange} />
                        </div>
                        <div>
                            <div className='d-flex flex-column mb-3 w-100'>
                                <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-dark font-sans'>Benefits:</h3>
                                <div className="position-relative">
                                    <input className='new-textinput p-2 mt-2 w-100' placeholder="None" type="text" value={formData.benefits} onChange={handleChange} />
                                    <div className='radius-5px d-flex justify-content-center align-items-center position-absolute mt-1' style={{ top: '50%', right: '5px', transform: 'translateY(-50%)', width: "30px", height: "25px", backgroundColor: "#E1E1E1" }}>
                                        <KeyboardArrowDownIcon onClick={handleOpenModal} />
                                        <SelectFeaturesModal open={openModal} handleClose={handleCloseModal} onConfirm={handleConfirm} />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-column mb-3 w-100'>
                                <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-dark font-sans'>Price:</h3>
                                <input className='new-textinput p-2 mt-2 w-100' name="planPrice" type="number" value={formData.planPrice} onChange={handleChange} />
                            </div>
                        </div>
                        <div className='d-flex flex-column mb-3 w-100'>
                            <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-dark font-sans'>Duration:</h3>
                            <div className="position-relative">
                            <Select
                            name="planPeriod"
                            value={formData.planPeriod}
                            onChange={handleChange}
                            style={{width:'100%',marginTop:'5px',borderRadius:"15px"}}
                            // sx={{
                            //     "& .MuiOutlinedInput-notchedOutline": {
                            //       border: "1 px solid red",
                            //     },
                            //   }}
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #D6D6D6",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #D6D6D6",
                                },
                            }}
                        >
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="quarterly">Quarterly</MenuItem>
                            <MenuItem value="yearly">Yearly</MenuItem>
                            <MenuItem value="half yearly">Half Yearly</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                        </Select>
                            </div>
                        </div>
                       


                        <div className='text-center mt-1' >
                            <Button variant="contained" style={{ width: "280px", height: "50px", backgroundColor: "#467578", color: "white" }} className='font-size-16 custom-font-600 radius-5px  text-capitalize font-sans' onClick={() => handleCreatePlan()}>
                                Save Plan
                            </Button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default AddPlanModaL;

