import React from 'react'
import { Card, Typography ,Box,Popover,MenuItem,MenuList} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NorthIcon from '@mui/icons-material/South';
import { useState } from 'react'; 
import ChartFour from './ChartFour';

const FourthCard = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
  
    return (
      <Card style={{ width: 300, height: 170 ,backgroundColor:'#E55353'}}>
        <div style={{display:'flex',justifyContent:'space-between',padding:'20px',color:'white'}}>
          <div>
            <Typography variant='h6'>44K  <span style={{fontSize:'15px'}}>(23.9% <NorthIcon style={{paddingTop:'5px'}}/>)</span></Typography>
            <Typography>Sessions</Typography>
         </div>
          <div>
            <MoreVertIcon onClick={handleClick} style={{cursor:'pointer'}}/>
            <Popover
              id={id}
              sx={{mt:1}}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{ p: 0 }}>
              <MenuList sx={{padding:'1px'}}>
                  <MenuItem onClick={handleClose}>Action</MenuItem>
                  <MenuItem onClick={handleClose}>Another Action</MenuItem>
                  <MenuItem onClick={handleClose}>Something else here...</MenuItem>
                  <MenuItem disabled onClick={handleClose}>Disabled Action</MenuItem>
                </MenuList>
              </Box>
            </Popover>
          </div>
        </div>
        <ChartFour/>
    </Card>
    )
}

export default FourthCard;