import React from 'react'
import { Card, Typography, Box, Popover, MenuItem, MenuList } from '@mui/material';
import ChartOne from './ChartOne';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SouthIcon from '@mui/icons-material/South';
import { useState } from 'react';



const FirstCard = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <Card style={{ width: 300, height: 170, backgroundColor: '#321FDB' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', color: 'white' }}>
        <div>
          <Typography variant='h6'>26K <span style={{ fontSize: '15px' }}>(-12.4% <SouthIcon style={{ paddingTop: '5px' }} />)</span></Typography>
          <Typography>Users</Typography>
        </div>

        <div>
          <MoreVertIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
          <Popover
            id={id}
            sx={{ mt: 1 }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box sx={{ p: 0 }}>
              <MenuList sx={{ padding: '1px' }}>
                <MenuItem onClick={handleClose}>Action</MenuItem>
                <MenuItem onClick={handleClose}>Another Action</MenuItem>
                <MenuItem onClick={handleClose}>Something else here...</MenuItem>
                <MenuItem disabled onClick={handleClose}>Disabled Action</MenuItem>
              </MenuList>
            </Box>
          </Popover>
        </div>
      </div>
      <ChartOne />
    </Card>
  )
}

export default FirstCard