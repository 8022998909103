import React from 'react';

const DownloadIcon = ({ height, width, color, className, style }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.367 7.7325C7.781 7.7325 8.117 8.0685 8.117 8.4825C8.117 8.8965 7.781 9.2325 7.367 9.2325H6.426C4.812 9.2325 3.5 10.5435 3.5 12.1565V17.0415C3.5 18.6605 4.816 19.9765 6.435 19.9765H17.575C19.187 19.9765 20.5 18.6635 20.5 17.0515V12.1675C20.5 10.5485 19.183 9.2325 17.566 9.2325H16.634C16.22 9.2325 15.884 8.8965 15.884 8.4825C15.884 8.0685 16.22 7.7325 16.634 7.7325H17.566C20.011 7.7325 22 9.7225 22 12.1675V17.0515C22 19.4915 20.014 21.4765 17.575 21.4765H6.435C3.99 21.4765 2 19.4875 2 17.0415V12.1565C2 9.7165 3.985 7.7325 6.426 7.7325H7.367ZM12.0008 3C12.4148 3 12.7508 3.336 12.7508 3.75L12.75 13.976L14.3858 12.334C14.6778 12.04 15.1518 12.04 15.4458 12.332C15.7388 12.624 15.7398 13.099 15.4478 13.393L12.5385 16.3137C12.5101 16.3429 12.4794 16.3698 12.4466 16.3941L12.5318 16.32C12.4996 16.3527 12.4646 16.382 12.4275 16.4078C12.4048 16.4234 12.3818 16.4376 12.358 16.4506C12.3465 16.4568 12.3344 16.463 12.3222 16.4687C12.3004 16.4791 12.278 16.4885 12.2551 16.4967C12.2436 16.5008 12.2321 16.5046 12.2206 16.5081C12.1924 16.5169 12.1635 16.5239 12.1339 16.5292C12.1272 16.5303 12.1212 16.5313 12.1151 16.5323C12.0912 16.536 12.0663 16.5386 12.0411 16.5399C12.0275 16.5406 12.0142 16.541 12.0008 16.541L11.961 16.538L11.8991 16.5341C11.8947 16.5336 11.8903 16.5329 11.8859 16.5322L12.0008 16.541C11.9561 16.541 11.9118 16.537 11.8683 16.5292C11.8382 16.5239 11.8093 16.5169 11.7811 16.5083C11.7699 16.5047 11.7588 16.5011 11.7478 16.4971C11.7249 16.489 11.7022 16.4796 11.6801 16.4691C11.6666 16.4627 11.6533 16.4559 11.6401 16.4487C11.6217 16.4385 11.6039 16.4277 11.5866 16.4162C11.5747 16.4084 11.5629 16.4 11.5512 16.3913C11.5227 16.3698 11.4957 16.3464 11.4705 16.3212L11.4698 16.32L8.5538 13.393C8.2618 13.099 8.2628 12.624 8.5558 12.332C8.8498 12.04 9.3238 12.04 9.6158 12.334L11.25 13.976L11.2508 3.75C11.2508 3.336 11.5868 3 12.0008 3Z"
                fill={color}
            />
        </svg>
    );
};

export default DownloadIcon;
