import React from 'react'
import { Box, Button, Modal } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { postTeam } from '../../../apihandler/apihandler';
import Swal from 'sweetalert2';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    //   p: 4,
};

const SuperAdminModal = ({ open, handleClose, getTeam}) => {


    const [postData, setPostData] = useState({
        name: "",
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPostData({ ...postData, [name]: value });
      };

    const handleCreateTeam = () => {
        handleClose();

        postTeam(postData)
            .then((res) => {
                setPostData({ name: "", email: "", password: "" });
                handleClose();
                getTeam();
                Swal.fire({
                    title: "Success!",
                    text: "Team created successfully.",
                    icon: "success",
                    confirmButtonColor: "#03565A",
                });
            })
            .catch((error) => {
                console.error("Error creating Team module:", error);
                handleClose();
                Swal.fire(
                    "Error",
                    "Failed to create Team. Please try again later.",
                    "error"
                );
            });
    };



    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <div>
                        <div className='d-flex justify-content-between p-3' style={{ backgroundColor: "#F1F2F5", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}>
                            <p className='mt-0 mb-0 font-size-20 custom-font-600 font-sans'>Add Super Admin</p>
                            <p className='mt-0 mb-0 font-size-16 custom-font-600 font-sans' onClick={handleClose} style={{ cursor: 'pointer' }}>Close</p>
                        </div>
                        <div className='p-3'>
                            <div className='d-flex flex-column mb-3'>
                                <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'>Name:</h3>
                                <input className='new-textinput p-2 mt-3 w-100 radius-10px' name='name' onChange={handleChange}
                                    value={postData.name} />
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'>Organization Email Id:</h3>
                                <input className='new-textinput p-2 mt-3 w-100 radius-10px' type="text" name="email" onChange={handleChange}
                                    value={postData.email} />
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'>Password:</h3>
                                <input className='new-textinput p-2 mt-3 w-100 radius-10px' type="text" name="password"
                                    onChange={handleChange}
                                    value={postData.password}
                                />
                            </div>
                            <div>
                                <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'> Responsibilities:</h3>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="Preparer" control={<Radio />} label="Preparer" />
                                    <FormControlLabel value="Approver" control={<Radio />} label="Approver" />
                                </RadioGroup>

                            </div>

                            <div className='text-center mt-1'>
                                <Button variant="contained" style={{ width: "280px", height: "50px" }} className='font-size-16 custom-font-600 radius-5px custom-common-theme-bg-color text-capitalize font-sans' onClick={() => handleCreateTeam()}>
                                    Invite Super Admin
                                </Button>
                            </div>
                        </div>
                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default SuperAdminModal