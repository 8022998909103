import React from 'react';
import { Box, Button, Modal } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  cities,
  signupFromSuperadmin,
  
  state,
} from "../../../../src/apihandler/apihandler";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
//   p: 4,
};

const AddCompanyModal = ({ open, handleClose,alluser }) => {

  const [ShowPwd, setShowPwd] = useState(false);
  const [Cpassword, setCPwd] = useState(false);
  const [allstate, setStateData] = useState([]);
  const [allcountry, setCountryData] = useState([]);
  const [allcities, setCitiesData] = useState([]);

  const navigate = useNavigate();

  const [error, seterror] = useState({
    org_name: true,
    email: true,
    password: true,
    confirmPwd: true,
  });
  const [Value, setValue] = useState({
    org_name: "",
    email: "",
    password: "",
    confirmPwd: "",
    city: "",
    state: "",
    isAdmin:"Yes",
    approved:"yes"
  });
  useEffect(() => {
    validation();
  }, []);
  function validation() {
    let temp = { ...error };
    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,10}[\.][a-z]{2,5}/g;
    temp.org_name =
      Value.org_name | (Value.org_name.length === 0) ? true : false;
    temp.email =
      pattern.test(Value.email) | (Value.email.length === 0) ? true : false;
    temp.password =
      (Value.password.length > 6) | (Value.password.length === 0)
        ? true
        : false;
    temp.confirmPwd =
      (Value.password === Value.confirmPwd) | (Value.confirmPwd.length === 0)
        ? true
        : false;
    seterror(temp);
  }

  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };
 
  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };

  function handleChange(field, value) {
    setValue({ ...Value, [field]: value });
  }



  const handleSignUp = async () => {
    if (
      Value.email.length === 0 ||
      Value.password.length === 0 ||
      Value.confirmPwd.length === 0
    ) {
      swal("", "Fill all the required fields!", "warning");
    } else {
      let data = {
        org_name: Value.org_name,
        email: Value.email,
        password: Value.password,
        confirmPwd: Value.confirmPwd,
        state: Value.state,
        city: Value.city,
        isAdmin: Value.isAdmin,
        approved:Value.approved
      };
  
      try {
        const response = await signupFromSuperadmin(data);
        if (response.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Company added successfully.",
            icon: "success",
            confirmButtonColor: "#03565A"  
          });
          // navigate('/company');
          handleClose();
          alluser();
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          swal("Error", error.response.data.error, "error");
        } else {
          swal("Error", "Something went wrong. Please try again.", "error");
        }
        console.log("Signup failed:", error);
      }
    }
  };
  

  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };

  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };


  return(
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <Box sx={style}>
      <div>
        <div className='d-flex justify-content-between p-3' style={{ backgroundColor: "#F1F2F5",borderTopLeftRadius:"15px",borderTopRightRadius:"15px" }}>
          <p className='mt-0 mb-0 font-size-20 custom-font-600 font-sans'>Add New Company</p>
          <p className='mt-0 mb-0 font-size-16 custom-font-600 font-sans' onClick={handleClose} style={{cursor:'pointer'}}>Close</p>
        </div>
        <div className='p-3'>
          <div className='d-flex flex-column mb-3'>
            <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'>Organization Name:</h3>
            <input className='new-textinput p-2 mt-3 w-100 radius-10px'  type="text" name="org_name" onChange={(e) => handleChange("org_name", e.target.value)}/>
          </div>
          <div className='d-flex flex-column mb-3'>
            <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'>Organization Email Id:</h3>
            <input className='new-textinput p-2 mt-3 w-100 radius-10px' type="text"  name="email" onChange={(e) => handleChange("email", e.target.value)}/>
          </div>
          <div className='d-flex' style={{ gap: "10px" }}>
            <div className='d-flex flex-column mb-3 w-100'>
              <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'>New Password:</h3>
              <input className='new-textinput p-2 mt-3 w-100 radius-10px'   id="fname"
              name="password" type={!ShowPwd ? "password" : "text"} onChange={(e) => handleChange("password", e.target.value)} />
            </div>
            <div className='d-flex flex-column mb-3 w-100'>
              <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'>Confirm New Password:</h3>
              <input className='new-textinput p-2 mt-3 w-100 radius-10px'  type={!Cpassword ? "password" : "text"} id="fname"
              name="confirmPwd" onChange={(e) => handleChange("confirmPwd", e.target.value)} />
            </div>
          </div>
          <div className='d-flex' style={{ gap: "10px" }}>
            <div className='d-flex flex-column mb-3 w-100 '>
              <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'>Select State:</h3>
              <Autocomplete
              className='mt-3'
              disablePortal
              id="combo-box-demo"
              // options={allstate?allstate:[]}
              options={!allstate ? [] : allstate}
              onChange={(e, value) => handleChange("state", value)}
              onInputChange={onInputChange}
              sx={{ width: 250, backgroundColor: "white",marginTop:1 , borderRadius: "10px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=<LocationOnIcon />
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      border: "none",
                    },
                  }}
                />
              )}
            />
            </div>
            <div className='d-flex flex-column mb-3 w-100'>
              <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-dark font-sans'>Select City:</h3>
              <Autocomplete
              className='mt-3'
              disablePortal
              id="combo-box-demo"
              // options={allcities?allcities:[]}
              options={!allcities ? [] : allcities}
              onChange={(e, value) => handleChange("city", value, e)}
              onInputChange={onInputChangecity}
              sx={{ width: 255, backgroundColor: "white",marginTop:1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=<LocationOnIcon/>
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      border: "none",
                    },
                  }}
                />
              )}
            />
            </div>
          </div>
          <div className='text-center mt-1'>
            <Button variant="contained" style={{ width: "280px", height: "50px" }} className='font-size-16 custom-font-600 radius-5px custom-common-theme-bg-color text-capitalize font-sans' onClick={handleSignUp}>
              Add Company
            </Button>
          </div>
        </div>
      </div>
    </Box>
  </Modal>
  )
  
}

export default AddCompanyModal;
