import React from 'react'
import { BrowserRouter, Navigate, Routes, useRoutes } from 'react-router-dom'
import Homepage from '../Bodysection/Homepage'
import CompanyPage from '../Bodysection/CompanyPage/CompanyPage'
import Navbar from '../Bodysection/Navbar'
import LayoutDesign from '../Layout/LayoutDesign'
import Login from '../AuthPages/Login'
import SignUp from '../AuthPages/Signup'
import SettingPage from '../Bodysection/SettingPage/SettingPage'
import SupportPage from '../Bodysection/SupportPage/SupportPage'
import SupportView from '../Bodysection/SupportPage/supportViewdetails'
import SupportData from '../Bodysection/SupportDeatails/SupportDeatails'
import TeamPage from '../Bodysection/TeamPage/TeamPage'
import Plan from '../Bodysection/Planpage/Plan'
import Logmodule from '../Bodysection/SettingPage/LogModuleTable'
import DeatailsPage from '../Bodysection/CompanyPage/DeatailsPage'
 import token from '../Bodysection/CompanyPage/Token'
 import Newticket from '../Bodysection/SupportPage/Newticketpage'
import Viewticket from '../Bodysection/SupportPage/Viewticket'
import EditTicket from '../Bodysection/SupportPage/Editticket'
import CompanySpecific from '../Bodysection/CompanyPage/CompanySpecific'


import PdfDownload from '../Bodysection/CompanyPage/PdfDownload'
import EditPlan from '../Bodysection/Planpage/EditPlan'
function CustomRouter() {
  let routes = useRoutes([ 
    {    
      path:'/',
      element:token()? <LayoutDesign/>:<Navigate to={'/login'}/>,
      
      children :[
        {path:'dashboard',element:<Homepage/>},
        {path:'company',element:<CompanyPage/>}, 
        {path:'team',element:<TeamPage/>},
        {path:'support',element:<SupportPage/>},
        {path:'support-viewdeatails/:id',element:<SupportView/>},
        {path:"support-newticket",element:<Newticket/>},
        {path:"support-viewticket/:id",element:<Viewticket/>},
        {path:"support-deatails/:id",element:<SupportData/>},
        {path:'plan',element:<Plan/>},
        {path:'setting',element:<SettingPage/>},
        {path:'logmodule',element:<Logmodule/>},
        {path:"support-editticket/:id",element:<EditTicket/>} ,
        {path:"company-details/:id",element:<DeatailsPage/>},
        // {path:"deatailspage/:id",element:<DeatailsPage/>},
        // {path:"support-newticket",element:<Newticket/>},
         {path:"company-specific",element:<CompanySpecific/>},
         {path:"company-specific/:id",element:<CompanySpecific/>},
        {path:"support-viewticket",element:<Viewticket/>},
        {path:"edit-plan",element:<EditPlan/>},
        {path:"download-invoice/:id",element:<PdfDownload/>},
        {path:'addcompany',element:<SignUp/>}

      ]
    },
    {path:'login',element:<Login/>},
  ])
  return routes; 
}

export default CustomRouter;