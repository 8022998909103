import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Dot,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    uv: 78,
  },
  {
    uv: 81,
  },
  {
    uv: 80,
  },
  {
    uv: 45,
  },
  {
    uv: 34,
  },
  {
    uv: 12,
  },
  {
    uv: 45,
  }
];

export default function ChartThree() {

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          const dataItem = payload[0].payload;
          return (
            <div style={{ backgroundColor: 'black', padding: '5px', color: 'white' }}>
              <p>{`Value: ${dataItem.uv}`}</p>
            </div>
          );
        }
    
        return null;
      };
    
      
  return (
    <ResponsiveContainer width="99%" height="45%">
      <AreaChart
        data={data}
        margin={{
          top: 5,
          right:0,
          left: 0,
          bottom: 5
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        {/* <XAxis dataKey="name" /> */}
        {/* <YAxis /> */}
        <Tooltip content={<CustomTooltip/>}/>
        <Area
          type="monotone"
          dataKey="uv"
          stackId="1"
          stroke="white"
          fill="#fad78d"
        />
        {/* <Area
          type="monotone"
          dataKey="pv"
          stackId="1"
          stroke="#82ca9d"
          fill="#82ca9d"
        /> */}
        {/* <Area
          type="monotone"
          dataKey="amt"
          stackId="1"
          stroke="#ffc658"
          fill="#ffc658"
        /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
}
