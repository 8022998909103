import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    uv: 40,
  },
  {
    uv: 30,
  },
  {
    uv: 20,
  },
  {
    uv: 27,
  },
  {
    uv: 67,
  },
  {
    uv: 90,
  },
  {
    uv: 49,
  }
  , {
    uv: 40,
  },
  {
    uv: 30,
  },
  {
    uv: 20,
  },
  {
    uv: 78,
  },
  {
    uv: 89,
  },
  {
    uv: 39,
  },
  {
    uv: 49,
  }
];

export default function ChartFour() {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          const dataItem = payload[0].payload;
          return (
            <div style={{ backgroundColor: 'black', padding: '5px', color: 'white' }}>
              <p>{`Value: ${dataItem.uv}`}</p>
            </div>
          );
        }
    
        return null;
      };
  return (
    <ResponsiveContainer width="99%" height="45%">
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 5
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <Tooltip content={<CustomTooltip/>}/>
        <Bar dataKey="uv" stackId="a" fill="rgba(214, 210, 210, 0.623)" />
        {/* <Bar dataKey="amt" stackId="a" fill="#888CB6" /> */}
        {/* <Bar dataKey="uv" fill="#AB9976" /> */}
      </BarChart>
    </ResponsiveContainer>
  );
}
