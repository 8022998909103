import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  Button,
  Card,
  TextField,
  Select,
  MenuItem,
  TextareaAutosize,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SelectFeaturesModal from "./SelectFeaturesModal";
import Popover from "@mui/material/Popover";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddPlanModaL from "./AddPlanModaL";
import { getAllPlans, updatePlans, deletePlans } from "../../../apihandler/apihandler";
import { useNavigate } from "react-router-dom";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useRef } from "react";
import Swal from "sweetalert2";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "12px",
        },
      },
    },
  },
});

const EditPlan = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [allPlan, setAllPlan] = useState([]);
  const [loading, setLoading] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [selectedValue, setSelectedValue] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [update, setUpdate] = useState(true);

  const navigate = useNavigate();
  const popoverRef = useRef(null);

  useEffect(() => {
    getPlan();
  }, [update]);

  const getPlan = async () => {
    try {
      const res = await getAllPlans();
      if (res.status === 200) {
        setAllPlan(res.data.data);
        console.log(res.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event, index) => {
    setAnchorEl2(event.currentTarget);
    setSelectedIndex(index);
    setOpenPopover(true);
  };

  const handleClick3 = (event, index) => {
    setOpenModal(true);
    setSelectedIndex(index);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
    setSelectedIndex(null);
    setOpenPopover(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover2" : undefined;

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    handleClose();
  };

  const handleChange2 = (event, index) => {
    const newPeriod = event.target.value;
    setAllPlan((prev) => {
      const updatedPlans = [...prev];
      if (updatedPlans[index]) {
        updatedPlans[index].planPeriod = newPeriod;
      }
      return updatedPlans;
    });
    handleClose2();
  };

  const handleOutsideClick = (event) => {
    if (anchorEl2 && !anchorEl2.contains(event.target)) {
      handleClose2();
    }
  };

  useEffect(() => {
    if (anchorEl2) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [anchorEl2]);

  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const handleConfirmFeatures = (selectedItems, displayString) => {
    setAllPlan((prev) => {
      const updatedPlans = [...prev];
      if (selectedIndex !== null && updatedPlans[selectedIndex]) {
        updatedPlans[selectedIndex].categorizedBenefits = selectedItems;
        updatedPlans[selectedIndex].benefits = displayString;
        console.log(displayString, "displayString---");
      }
      console.log(updatedPlans, "updatedPlans---");
      setUpdatedData(updatedPlans);
      return updatedPlans;
    });

    setSelectedFeatures(selectedItems);
    handleCloseModal();
  };

  const handleFieldChange = (index, field, value) => {
    const updatedPlans = [...allPlan];
    updatedPlans[index][field] = value;
    setAllPlan(updatedPlans);
  };

  const handleSave = async () => {
    try {
      // Call API to save the updated plans
      // Example: await savePlans(allPlan);
      console.log(updatedData, "updatedData--");
      console.log("Plans saved:", allPlan);
      const fd = {
        plans: allPlan
      }
      console.log(fd,"fd-->")
      await updatePlans(fd)
        .then((res) => {
          Swal.fire({
            title: "Success!",
            text: "Plans Updated successfully.",
            icon: "success",
            confirmButtonColor: "#03565A",
          });
          console.log(res, "res-------->");
        })
        .catch((error) => {
          console.error("Error creating plan:", error);
          // setIsOpen(false);
          Swal.fire({
            icon: "error",
            title: "error",
            text: "Failed to update plan. Please try again later..",
            confirmButtonColor: "#03565A",
          });
        });
    } catch (error) {
      console.error("Error saving plans:", error);
    }
  };

  const deletePlan = async (id) => {
    handleClose()
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#03565A",
      cancelButtonColor: "#03565A",
      confirmButtonText: "Yes, delete it!"
    });

    if (result.isConfirmed) {
      const res = await deletePlans(id);
      if (res.status === 200) {
        handleClose()
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#03565A",
        });
        getPlan();
      }
    }
  };

  return (
    <>
      <div className="px-4 py-3">
        <div className="px-4 d-flex justify-content-between align-items-center ">
          <p className="mt-0 mb-0 font-size-20 custom-font-600 font-sans">
            Add/Edit Plan
          </p>
          <div className="d-flex" style={{ gap: "10px" }}>
            <h6
              className="mt-3 font-size-16 custom-font-500 text-secondary font-sans"
              onClick={handleOpenModal2}
              style={{ cursor: "pointer" }}
            >
              <span style={{ marginRight: "5px" }}>
                <AddIcon style={{ width: "22px", height: "22px" }} />
              </span>
              Add more Plan
            </h6>
            <Button
              variant="outlined"
              className="d-flex align-items-center primary-border custom-common-theme-text-color font-size-16 custom-font-600 text-capitalize font-sans"
              style={{ width: "100px", height: "50px" }}
              onClick={handleSave}
            >
              <SaveIcon style={{ marginRight: "8px" }} />
              Save
            </Button>
            <AddPlanModaL
              open={openModal2}
              handleClose={handleCloseModal2}
              setOpenModal2={setOpenModal2}
              setUpdate={setUpdate}
              update={update}
            />
          </div>
        </div>
        <div className="mt-3">
          <div className="px-4 py-3">
            <div className="mt-3">
              <Card
                className="w-100  no-shadow gray-border"
                style={{ borderRadius: "13px" }}
              >
                <div>
                  <table className="table table-bordered">
                    <thead style={{ backgroundColor: "#F8F8F8" }}>
                      <tr>
                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter custom-backgroundColor" style={{ minWidth: "20%" }}>
                          Plan Name
                        </th>
                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter w-25 custom-backgroundColor" style={{ minWidth: "20%" }}>
                          Description
                        </th>
                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter w-50 custom-backgroundColor" style={{ minWidth: "20%" }}>
                          Benefits
                        </th>
                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter custom-backgroundColor" style={{ minWidth: "20%" }}>
                          Price
                        </th>
                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter custom-backgroundColor " style={{ minWidth: "20%" }}>
                          Duration
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allPlan.map((elem, index) => {
                        return (
                          <tr key={elem._id}>
                            <td className="text-left">
                              <TextareaAutosize
                                className="font-size-16 custom-font-500 text-dark font-sans"
                                fullWidth
                                variant="outlined"
                                style={{
                                  border: "none",
                                  width: "100%",
                                  outline: "none",
                                  resize: "none",
                                }}
                                value={elem.planName}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    "planName",
                                    e.target.value
                                  )
                                }
                              /><span style={{ color: 'red', cursor: 'pointer' }} onClick={() => deletePlan(elem._id)}><DeleteSweepIcon /></span>
                            </td>
                            <td className="text-left">
                              <TextareaAutosize
                                className="font-size-16 custom-font-500 text-dark font-sans"
                                fullWidth
                                variant="outlined"
                                style={{
                                  border: "none",
                                  width: "100%",
                                  outline: "none",
                                  resize: "none",
                                }}
                                value={elem.planDescription}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    "planDescription",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td className="text-left">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <div className="flex-column">
                                    <p className="mt-0 mb-0 font-size-12 custom-font-500 text-secondary font-sans">
                                      Access Management
                                    </p>
                                    {elem.categorizedBenefits &&
                                      elem.categorizedBenefits.accessManagement &&
                                      elem.categorizedBenefits.accessManagement
                                        .length > 0 ? (
                                      <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">
                                        {elem.categorizedBenefits.accessManagement.join(
                                          ","
                                        )}
                                      </p>
                                    ) : (
                                      <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">
                                        No features available
                                      </p>
                                    )}
                                  </div>
                                  <div className="flex-column mt-1">
                                    <p className="mt-0 mb-0 font-size-12 custom-font-500 text-secondary font-sans">
                                      Dataset
                                    </p>
                                    {elem.categorizedBenefits &&
                                      elem.categorizedBenefits.dataset &&
                                      elem.categorizedBenefits.dataset.length >
                                      0 ? (
                                      <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">
                                        {elem.categorizedBenefits.dataset.join(
                                          ", "
                                        )}
                                      </p>
                                    ) : (
                                      <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">
                                        No features available
                                      </p>
                                    )}
                                  </div>
                                  <div className="flex-column mt-1">
                                    <p className="mt-0 mb-0 font-size-12 custom-font-500 text-secondary font-sans">
                                      Workspace Functions
                                    </p>
                                    {elem.categorizedBenefits &&
                                      elem.categorizedBenefits
                                        .workspaceFunctions &&
                                      elem.categorizedBenefits.workspaceFunctions
                                        .length > 0 ? (
                                      <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">
                                        {elem.categorizedBenefits.workspaceFunctions.join(
                                          ", "
                                        )}
                                      </p>
                                    ) : (
                                      <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">
                                        No features available
                                      </p>
                                    )}
                                  </div>
                                  <div className="flex-column mt-1">
                                    <p className="mt-0 mb-0 font-size-12 custom-font-500 text-secondary font-sans">
                                      No of users
                                    </p>
                                    {elem.categorizedBenefits &&
                                      elem.categorizedBenefits.noOfUsers &&
                                      elem.categorizedBenefits.noOfUsers.length >
                                      0 ? (
                                      <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">
                                        {elem.categorizedBenefits.noOfUsers.join(
                                          ","
                                        )}
                                      </p>
                                    ) : (
                                      <p className="mt-0 mb-0 font-size-16 custom-font-500 text-secondary font-sans">
                                        No features available
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="d-flex justify-content-center align-items-center Table-headerText"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    backgroundColor: "#E1E1E1",
                                  }}
                                >
                                  <KeyboardArrowDownIcon
                                    onClick={(e) => handleClick3(e, index)}
                                  />
                                  <SelectFeaturesModal
                                    handleClose={() => {
                                      setOpenModal(false); // Close the modal
                                      setSelectedIndex(null); // Reset the selected index
                                    }}
                                    open={openModal && selectedIndex === index}
                                    onConfirm={
                                      selectedIndex !== null
                                        ? handleConfirmFeatures
                                        : null
                                    }
                                    selectedFeatures={elem.categorizedBenefits}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="text-left">
                              <TextareaAutosize
                                className="font-size-12  custom-font-600 font-sans"
                                fullWidth
                                variant="outlined"
                                value={elem.planPrice}
                                style={{
                                  border: "none",
                                  width: "100%",
                                  outline: "none",
                                  resize: "none",
                                }}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    "planPrice",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td className="text-left">
                              <div className="d-flex align-items-center justify-content-between" style={{ gap: "1px" }}>
                                <p className="mb-0 mt-0 font-size-12  custom-font-600 font-sans">{elem.planPeriod}</p>
                                <div
                                  className="d-flex justify-content-center align-items-center ml-1"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    backgroundColor: "#E1E1E1",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleClick2(e, index)}
                                >
                                  <KeyboardArrowDownIcon />
                                </div>
                                <Popover
                                  id={id2}
                                  open={open2 && selectedIndex === index}
                                  anchorEl={anchorEl2}
                                  onClose={handleClose2}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  PaperProps={{
                                    style: {
                                      width: "140px",
                                      height: "230px",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  <div className="px-3 p-1 ml-2 mt-1">
                                    <ThemeProvider theme={theme}>
                                      <div>
                                        <RadioGroup
                                          className="font-size-12"
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          value={selectedValue[index] || elem.planPeriod}
                                          name="radio-buttons-group"
                                          onChange={(e) => handleChange2(e, index)}
                                        >
                                          <FormControlLabel
                                            value="weekly"
                                            control={<Radio />}
                                            label="Weekly"
                                            className="custom-font-500 font-sans"
                                          />
                                          <FormControlLabel
                                            value="monthly"
                                            control={<Radio />}
                                            label="Monthly"
                                            className="custom-font-500 font-sans"
                                          />
                                          <FormControlLabel
                                            value="quarterly"
                                            control={<Radio />}
                                            label="Quarterly"
                                            className="custom-font-600 font-sans"
                                          />
                                          <FormControlLabel
                                            value="half yearly"
                                            control={<Radio />}
                                            label="Half Yearly"
                                            className="custom-font-500 font-sans"
                                          />
                                          <FormControlLabel
                                            value="yearly"
                                            control={<Radio />}
                                            label="Yearly"
                                            className="custom-font-500 font-sans"
                                          />
                                        </RadioGroup>
                                      </div>
                                    </ThemeProvider>
                                  </div>
                                </Popover>
                              </div>

                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPlan;
