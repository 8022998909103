import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "../../Assets/Logo/MainLogo.svg";
import { Link } from "react-router-dom";
import "../Styles/Navbar.css";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function Navbar() {
  const [checked, setChecked] = useState(true);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLabelClick = () => {
    setChecked(!checked);
  };

  const Logout=()=>{ 

     localStorage.removeItem("auth_token")
     navigate("/login")
  }

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#03565A" }}>
      <Container
        sx={{
          padding: "5px",
          // margin: "0px 8px",
          maxWidth: { xs: "100%",  },
        }}
      >
        <Toolbar
          disableGutters
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex",alignItems:"center" }}>
            <Link to={"/dashboard"}> 
              <img src={logo} sx={{ display: { xs: "none", md: "flex" } }} />
            </Link>  
            {/* <p style={{marginTop:"19px"}} >Superadmin</p> */}

          </div>


          <div
            style={{ display: "flex", fontSize: "0px", gap: "40px", mr: "400px", fontFamily: "sans-serif" }}
          >
            <Link to={"/dashboard"} style={{ fontSize: "15px" }}>Dashboard</Link>
            <Link to={"/company"} style={{ fontSize: "15px" }}>Company</Link>
            <Link to={"/team"} style={{ fontSize: "15px" }}>Team</Link>
            <Link to={"/support"} style={{ fontSize: "15px" }}>Support</Link>
            {/* <Link to={"/support-deatails"} style={{ fontSize: "15px" }}>SupportDeatails</Link> */}
            <Link to={"/plan"} style={{ fontSize: "15px" }}>Plan</Link>
            {/* <Link to={"/setting"} style={{ fontSize: "15px" }}>Setting</Link> */}
          </div> 



          <Box >
            <Tooltip title="Open Accounts" >
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "0px", ml: "-50px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  backgroundColor: checked ? "#03565A" : "white",
                  color: checked ? "white" : "black",
                  "&:hover": {
                    backgroundColor: checked ? "#03565A" : "white", // Hover color same as default
                  },
                }}
              >
                <Typography textAlign="center">Profile</Typography>
                <Typography
                  textAlign="center"
                  onClick={() => Logout()}
                >
                  Logout
                </Typography>
                {checked ? (
                  <DarkModeIcon
                    style={{
                      cursor: "pointer",
                      width: "35px",
                      height: "35px",
                      transform: "scale(1)",
                      transition: "transform 3s",
                      color: "black",
                    }}
                    onClick={handleLabelClick}
                  />
                ) : (
                  <LightModeIcon
                    id="new"
                    checked={checked}
                    onClick={handleLabelClick}
                    style={{
                      cursor: "pointer",
                      width: "35px",
                      height: "35px",
                      color: "yellow",
                    }}
                  />
                )}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
