import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addPayment, getAlluser, getAlluserById } from '../../../apihandler/apihandler';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Homepage from '../Homepage';
import { getsubScriptions, getInvoiceById, getProfile, getAllPlans, GetSubscriptionPlansDetails, addPlan, getPaymentByCustomerId,resetPassword } from '../../../apihandler/apihandler';
import moment from 'moment';
import { Button, Typography } from '@mui/material';
import FirstCard from '../Hompage/Cards/FirstCard/FirstCard';
import SecondCard from '../Hompage/Cards/SecondCard/SecondCard';
import ThirdCard from '../Hompage/Cards/ThirdCard/ThirdCard';
import FourthCard from '../Hompage/Cards/FourthCard/FourthCard';
import axios from 'axios';
import Swal from 'sweetalert2';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';




export default function DeatailsPage() {
  const emails = ['username@gmail.com', 'user02@gmail.com'];
  const navigate = useNavigate()
  const [invoices, setInvoices] = useState('')
  const [payment, setPayment] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [subscriptions, setSubscriptions] = useState([])
  const [cancellationInitiated, setCancellationInitiated] = useState(false);
  const [plans, setPlans] = useState([]);
  const [custId, setcustId] = useState('');
  const [plandetails, setPlandetails] = useState({})
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);

  const [orderId, setOrderId] = useState('');
  const [amount, setAmount] = useState(0);
  const [password, setPassword] = useState('')

  const { id } = useParams();
  console.log(id)

  useEffect(() => {
    fetchCompany();
    getsubscriptions();
    getInvoice();
    getAllPayments();
  }, []);

  const getsubscriptions = async () => {
    try {
      const user_id = id
      const res = await getsubScriptions(user_id);
      console.log(res, 'all subscriptions -------->');
      setSubscriptions(res.data);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  }

  const cancelUser = async () => {
    try {
      const config = {
        headers: {
          '_id': id // Assuming id is defined somewhere in your frontend code
        }
      };
      const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/auth/login-cancel`, {}, config);
      console.log(res, "response for cancel user");
    } catch (error) {
      console.error(error);
    }
  };

  const getplanDetails = async (planid) => {
    try {
      const response = await GetSubscriptionPlansDetails(planid)
      console.log(response.data.data.planPrice, "------------")
      if (response.status === 200) {
        setPlandetails(response.data.data);
      }

    } catch (error) {

    }
  }


  const handleCancelUser = async () => {
    try {

      // Show confirmation dialog
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Once cancelled, User will not be able to login again!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel login!',
      });

      // If user confirms
      if (result.isConfirmed) {
        setCancellationInitiated(true);
        await cancelUser();
        Swal.fire(
          'Cancelled!',
          'Login has been cancelled.',
          'success'
        );
      }
    } catch (error) {
      console.error(error);
      Swal.fire(
        'Error',
        'Failed to cancel login. Please try again later.',
        'error'
      );
    }
  }

  const getInvoice = async () => {
    const res = await getInvoiceById(id);
    console.log(res, 'resid---')
    if (res.status === 200) {
      setInvoices(res.data.data)
    } else {
      console.log('error')
    }

  }
  const planhandler = async () => {
    try {
      const res = await getAllPlans();
      if (res.status === 200) {
        console.log(res,"response>>>>>>")
        setPlans(res.data.data)
      }

    } catch (error) {
      console.log(error)
    }
    // finally {
    //     setLoading(false); // Set loading to false regardless of success or failure
    // }
  }

  const fetchCompany = async () => {
    try {
      const response = await getAlluserById(id);
      console.log("Fetched user data:", response);
      if (response.status === 200) {
        console.log(response.data.user, 'user--------------------')
        setCompanyData(response.data.user);
      }
    } catch (error) {
      console.error('Error fetching company details:', error);
    }
  };



  const handleClickOpen = () => {
    setOpen(true);
    planhandler();
  };

  // const handleClose = (value) => {
  //   setOpen(false);
  //   setSelectedValue(value);
  //   getplanDetails(value);
  //   checkoutHandler(value)
  // };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    getplanDetails(value).then(() => {
      // checkoutHandler(value);
    }).catch((error) => {
      console.error('Error fetching plan details:', error);
    });
  };

  const generateOrderId = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let orderIds = '';
    for (let i = 0; i < 10; i++) {
      orderIds += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return orderIds;
  };

  // const checkoutHandler = async (value)=>{
  //   const bodyData = {
  //       plan_price:plandetails.planPrice,
  //       customer_id:id,
  //       subscription_start_date: new Date(),
  //       subscription_end_date: new Date(new Date().setMonth(new Date().getMonth() + 1)) ,
  //       plan_id: value,
  //       plan_name:plandetails.planName,
  //       // billingDetails,
  //       // shippingDetails,
  //       receipt:generateOrderId(),
  //   };


  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/createpaymentOrder`,bodyData);
  //     console.log(response);
  //     setOrderId(response.data.id);
  //     setAmount(response.data.amount);

  //     // Open Razorpay modal
  //     // openRazorpayModal(response.data.id);
  //   } catch (error) {
  //     console.error('Failed to create order:', error);
  //   }
  // };

  const calculateSubscriptionEndDate = (startDate, period) => {
    const date = new Date(startDate);
    switch (period) {
      case 'monthly':
        date.setMonth(date.getMonth() + 1);
        break;
      case 'quarterly':
        date.setMonth(date.getMonth() + 3);
        break;
      case 'yearly':
        date.setFullYear(date.getFullYear() + 1);
        break;
      default:
        break;
    }
    return date;
  };

  const addSubscriptionPlan = async (email) => {
    const data = {
      user_id: id,
      plan_price: email?.planPrice,
      users:email?.users,
      plan_id: email?._id,
      plan_name: email?.planName,
      subscription_start_date:new Date(),
      subscription_end_date:calculateSubscriptionEndDate(new Date(),email.planPeriod)
    }
    const res = await addPlan(data)
    if (res.status === 200) {
      getsubscriptions();
    }
  }


  const addPayments = async () => {
    const data = {
      customer_id: id
    }
    const res = await addPayment(data)
    if (res.status === 200) {
      getAllPayments();
    }
  }

  const getAllPayments = async () => {
    try {
      const customer_id = id;
      const res = await getPaymentByCustomerId(customer_id);
      if (res.status === 200) {
        setPayment(res.data.getpayment);
      }
    } catch (error) {
      console.error('Error fetching payments:', error);
    }
  };

  console.log(payment, "====-=-=-=-=");

  const generatePassword = (length = 9) =>{
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@";
    let generatedPassword = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      generatedPassword += charset[randomIndex];
    }
    return generatedPassword;

  }

  const handleResetPassword = async () => {
    const newPassword = generatePassword();

    const data = {
      tempPassword:newPassword,
      userId:id
    }
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Once Reset, User will not be able to login with previous password!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Reset password!',
      });

      if(result.isConfirmed){
        await resetPassword(data);
        Swal.fire(
          'Reset!',
          'Password has been reset.Check your mail for your new password!',
          'success'
        );
       await fetchCompany();
      }
    } catch (error) {
      console.error('Error resetting password', error);
    }
  };



  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ margin: '30px' }}>
          <p className='p-1'> <b>Name :</b> <span>{companyData.organisation}</span></p>
          <p className='p-1'> <b>Email :</b> <span>{companyData.email}</span></p>
          <p className='p-1'> <b>Location :</b> <span>{companyData.state}</span></p>
          <p className='p-1'> <b>Created Date :</b> <span>{moment(companyData.createdAt).format('DD/MM/YYYY')}</span></p>
          <div>
            <Button variant="outlined" onClick={()=> handleResetPassword()} >
              Reset Password
            </Button>
            <p style={{marginTop:'10px'}}>{companyData.temp_password?<span style={{color:'green'}}>Temporary password: <span style={{background:'blue',color:'white'}}>{companyData.temp_password}</span></span>:null}</p>
          </div>
        </div>

        <div>
          {companyData.has_subscribed === "free" ? "" : (
            <Button
              style={{ margin: '30px', color: 'white', backgroundColor: 'red', padding: '5px 15px' }}
              disabled={companyData.has_subscribed === "cancelled" || cancellationInitiated}
              onClick={() => handleCancelUser()}
            >
              {companyData.has_subscribed === "cancelled" || cancellationInitiated ? "Cancelled" : "cancel"}
            </Button>
          )}

        </div>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', margin: '40px 0px' }}>
        <div><FirstCard /></div>
        <div><SecondCard /></div>
        <div><ThirdCard /></div>
        <div><FourthCard /></div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '30px' }}>
        <div style={{ marginLeft: '30px' }}><Typography variant='h6'>Subscribed Plans:</Typography></div>
        <div>
          {/* <Typography variant="subtitle1" component="div">
        Selected: {selectedValue}
      </Typography>
      <br /> */}
          <Button variant="outlined" onClick={handleClickOpen}>
            Add Plan
          </Button>
          <Dialog onClose={() => handleClose(selectedValue)} open={open}>
            <DialogTitle>Choose a plan</DialogTitle>
            <List sx={{ pt: 0 }}>
              {plans.map((email) => (
                <ListItem disableGutters key={email._id} onClick={() => addSubscriptionPlan(email)}>
                  <ListItemButton onClick={() => handleClose(email._id)}>
                    {/* <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar> */}
                    <ListItemText primary={email.planName} />
                    <ListItemText primary={email.planPrice} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Dialog>
        </div>
      </div>
      <div style={{ margin: '30px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#DDDDDD79' }}>
              <TableRow>
                <TableCell>Plan Name</TableCell>
                <TableCell align="center">Plan Price</TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">End Date</TableCell>
                <TableCell align="center">Payment</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.length > 0 ? subscriptions.map((sub, index) => {
                return (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {sub.plan_name}
                    </TableCell>
                    <TableCell align="center">{sub.plan_price}</TableCell>
                    <TableCell align="center">{moment(sub.subscription_start_date).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="center">{moment(sub.subscription_end_date).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="center">{sub.subscription_via}</TableCell>
                    <TableCell align="center">{sub.status === 'active' ? <span style={{ color: 'red' }}>{sub.status}</span> : <span>{sub.status}</span>}</TableCell>
                  </TableRow>
                )

              }) : <TableCell >You dont have any Plan!</TableCell>}

            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ marginLeft: '30px' }}><Typography variant='h6'>Invoices:</Typography></div>
        <Button variant="outlined" style={{ marginRight: '30px' }} onClick={addPayments}>
          Add Payment
        </Button>
      </div>

      <div style={{ margin: '30px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#DDDDDD79' }}>
              <TableRow>
                <TableCell>SL No	</TableCell>
                <TableCell align="center">Subscription Date</TableCell>
                <TableCell align="center">Plan Price</TableCell>
                <TableCell align="center">Plan Name	</TableCell>
                <TableCell align="center">Payment Via</TableCell>
                <TableCell align="center">Invoice Status</TableCell>
                <TableCell align="center">Payment Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payment?.length > 0 ? payment?.map((payment, index) => {
                return (
                  <TableRow style={{ cursor: 'pointer' }}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{moment(payment?.subscription_id?.subscription_start_date).format("DD/MM/YYYY")}</TableCell>
                    <TableCell align="center">{payment?.subscription_id?.plan_price}</TableCell>
                    <TableCell align="center">{payment?.subscription_id?.plan_name}</TableCell>
                    <TableCell align="center">{payment?.payment_via}</TableCell>
                    <TableCell align="center">{payment?.invoice_status}</TableCell>
                    <TableCell align="center">{payment?.payment_status}</TableCell>
                  </TableRow>
                )

              }) : <TableCell >No Invoice Created!</TableCell>}

            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
