// import {React,useState}from 'react';
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import "../Bodysection/CompanyPage/sidebar.css"
import {
  cities,
  signupFromSuperadmin,
  
  state,
} from "../../apihandler/apihandler";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

const SignUp = (props) => {
  const [ShowPwd, setShowPwd] = useState(false);
  const [Cpassword, setCPwd] = useState(false);
  const [allstate, setStateData] = useState([]);
  const [allcountry, setCountryData] = useState([]);
  const [allcities, setCitiesData] = useState([]);

  const navigate = useNavigate();

  const [error, seterror] = useState({
    org_name: true,
    email: true,
    password: true,
    confirmPwd: true,
  });
  const [Value, setValue] = useState({
    org_name: "",
    email: "",
    password: "",
    confirmPwd: "",
    city: "",
    state: "",
    isAdmin:"Yes",
    approved:"yes"
  });
  useEffect(() => {
    validation();
  }, []);
  function validation() {
    let temp = { ...error };
    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,10}[\.][a-z]{2,5}/g;
    temp.org_name =
      Value.org_name | (Value.org_name.length === 0) ? true : false;
    temp.email =
      pattern.test(Value.email) | (Value.email.length === 0) ? true : false;
    temp.password =
      (Value.password.length > 6) | (Value.password.length === 0)
        ? true
        : false;
    temp.confirmPwd =
      (Value.password === Value.confirmPwd) | (Value.confirmPwd.length === 0)
        ? true
        : false;
    seterror(temp);
  }

  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };
 
  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };

  function handleChange(field, value) {
    setValue({ ...Value, [field]: value });
  }

  const handleSignUp = async () => {
    if (
      Value.email.length === 0 ||
      Value.password.length === 0 ||
      Value.confirmPwd.length === 0
    ) {
      swal("", "Fill all the required fields!", "warning");
    } else {
      let data = {
        org_name: Value.org_name,
        email: Value.email,
        password: Value.password,
        confirmPwd: Value.confirmPwd,
        state: Value.state,
        country: Value.country,
        isAdmin: Value.isAdmin,
        approved:Value.approved
      };
  
      try {
        const response = await signupFromSuperadmin(data);
        if (response.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Company added successfully.",
            icon: "success",
            confirmButtonColor: "#03565A"  
          });
          navigate('/company');
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          swal("Error", error.response.data.error, "error");
        } else {
          swal("Error", "Something went wrong. Please try again.", "error");
        }
        console.log("Signup failed:", error);
      }
    }
  };
  

  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };

  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };

  const responseError = (res) => {
    swal("", "hii chinmay", "error");
    // console.log(res,'error response from google')
  };

  return (
    <div className="">
      <div className="mt-5">

        <div className="input_box">
          <div className="password-signin">
            <label style={{fontSize:'18px'}}>Enter your organization name</label><br></br>
            <input
              style={{width:'100%',padding:'10px',marginTop:'5px'}}
              name="org_name"
              placeholder=""
              type="text"
              onChange={(e) => handleChange("org_name", e.target.value)}
            />
          </div>
          <div className="password-signin" style={{marginTop:'15px'}}>
            <label style={{fontSize:'18px'}}>Enter your organization e-mail address</label><br></br>
            <input
            style={{width:'100%',padding:'10px',marginTop:'5px'}}
              name="email"
              placeholder=""
              type="text"
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </div>

          <div className="password-signin" style={{marginTop:'15px'}}>
            <label style={{fontSize:'18px'}}>Set a password for your account</label><br></br>
            <input
            style={{width:'100%',padding:'10px',marginTop:'5px'}}
              id="fname"
              name="password"
              placeholder=""
              type={!ShowPwd ? "password" : "text"}
              onChange={(e) => handleChange("password", e.target.value)}
            />
            {/* <i
              onClick={() => setShowPwd(!ShowPwd)}
              style={{ marginTop: "10px" }}
              className={ShowPwd ? "fas fa-eye" : "far fa-eye-slash"}
            /> */}
          </div>

          <div className="password-signin" style={{marginTop:'15px'}}>
            <label style={{fontSize:'18px'}}>Confirm your new password</label><br></br>
            <input
            style={{width:'100%',padding:'10px',marginTop:'5px'}}
              id="fname"
              name="confirmPwd"
              placeholder=""
              type={!Cpassword ? "password" : "text"}
              onChange={(e) => handleChange("confirmPwd", e.target.value)}
            />
            {/* <i
              onClick={() => setCPwd(!Cpassword)}
              style={{ marginTop: "10px" }}
              className={Cpassword ? "fas fa-eye" : "far fa-eye-slash"}
            /> */}
          </div>

          <div className="d-flex mt-4 password-signin" style={{ color: "#696F79" }}>
            <label>Select City</label>
            <label style={{ marginLeft: "195px" }}>Select State</label>
          </div>

          <div className="row_box">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // options={allcities?allcities:[]}
              options={!allcities ? [] : allcities}
              onChange={(e, value) => handleChange("city", value, e)}
              onInputChange={onInputChangecity}
              sx={{ width: 350, backgroundColor: "white" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=<LocationOnIcon/>
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      border: "none",
                    },
                  }}
                />
              )}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // options={allstate?allstate:[]}
              options={!allstate ? [] : allstate}
              onChange={(e, value) => handleChange("state", value)}
              onInputChange={onInputChange}
              sx={{ width: 350, marginLeft: 5, backgroundColor: "white" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=<LocationOnIcon />
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      border: "none",
                    },
                  }}
                />
              )}
            />
          </div>
          <div className="btn_main">
            <Button className="create_btn" onClick={handleSignUp}>
              Create Company
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
