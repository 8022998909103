import { useEffect, useState } from "react";
import { Card } from 'react-bootstrap'
import "./support.css"
import view from "../../../Assets/viewimg.png";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ListGroup } from 'react-bootstrap';
import { getTicketById, getAlluser, assigntoAgent, getTeam, removeAssigntoAgent, getProfile } from "../../../apihandler/apihandler";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import ClearIcon from '@mui/icons-material/Clear';
import uploadimage from '../../../Assets/upload.svg'
import ViewImageModal from "./ImageModal";

const style = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 2,
};




const SupportView = () => {

    const { id } = useParams();
    // console.log(id)
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchValue, setSearchValue] = useState("");
    const [admin, setAdmin] = useState(true)
    const [ticketData, setTicketData] = useState({})
    const [creatorId, setCreatorId] = useState('')
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState(true)
    const [update, setUpdate] = useState(true)
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [team, setTeam] = useState([])
    const [isViewImage, setIsViewImages] = useState(false);

    const [imageView ,setImageView] = useState('');

    const handleSearch = () => {
        // Handle search functionality here
        console.log("Searching for:", searchValue);
    };

    const agents = [
        { name: 'Varun', initial: 'V', bgColor: 'bg-primary', },
        { name: 'Krishna', initial: 'K', bgColor: 'bg-secondary' },
        { name: 'Subu', initial: 'S', bgColor: 'bg-warning' },
        { name: 'Sidhu', initial: 'S', bgColor: 'bg-danger' },
    ];


    const filteredAgents = team.filter(agent =>
        agent.name.toLowerCase().includes(searchTerm.toLowerCase())
    );



    useEffect(() => {
        const fetchData = async () => {
            await fetchTicket();
        };

        fetchData();
    }, [update]);

    useEffect(() => {
        getAgent();
        getSuperadminUser();
    }, [])



    const getAgent = async () => {
        try {
            const res = await getTeam();
            console.log(res, "resteam")
            setTeam(res.data.data)

        } catch (error) {
            console.log(error)
        }
    }


    const fetchTicket = async () => {

        try {
            const response = await getTicketById(id)
            console.log("get ticket by id show", response)
            if (response.data.data) {
                console.log(response.data.data, "ticket----------------------------------")
                setTicketData(response.data.data)
                setCreatorId(response.data.data.customer_id);
            }
        }
        catch (error) {
            console.log("erro fetching ticket deatails")
        }

    }


    const allUser = async (creatorId) => {
        try {

            const user = await getAlluser();
            console.log(user, "user---------->")
            const data = user.data.fetchUser;
            const customerData = data.find((email) => email._id === creatorId);
            console.log(customerData, "customerData---->")
            if (customerData) {
                setEmail(customerData.email);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (creatorId) {
            allUser(creatorId);
        }
    }, [creatorId]);

    const handleChangeAgent = (agent) => {
        console.log(agent);
        setSelectedAgent(agent._id);
        // setAgentname(agent.name)
        // Perform any additional actions with the selected agent
    };


    const handleAssign = async () => {
        try {

            const data = {
                id: id,
                agent_id: selectedAgent
            }
            console.log(data, "data----->>")
            const res = await assigntoAgent(data);
            // console.log(ticketId,"ticket_id-")

            console.log(res, "assignagent--");
            swal("", "Successfully Assigned your Ticket", "success")
            handleClose();
            setUpdate(!update);
            setSearchTerm('')

        } catch (error) {
            console.log(error);
        }
    }

    const removeAgent = async (id) => {
        console.log(id, "__id__")
        const data = {
            id: id
        }
        try {
            const res = await removeAssigntoAgent(data)
            console.log(res, "remove agent");
            setUpdate(!update)
        } catch (error) {
            console.log(error)

        }
    }


    const getSuperadminUser = async () => {
        try {

            const res = await getProfile();
            console.log(res, "user----")
            if (res.status === 200) {

                if (res.data.profile.isadmin === 'no') {

                    setAdmin(false)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <div className=' mb-4 d-flex justify-content-center mt-4'>


                <div >
                    <label className='custom-font-700 font-size-20'>Contact Us</label>
                    <Card className='viewcard no-shadow mb-4 p-4 mt-4'>

                        <div className='row g-3'>
                            <div className='col-12 col-md-6'>
                                <label className='text-gray font-size-12 custom-font-700 mt-0 mb-0 font-sans'>Full name <span className="text-danger">*</span></label>
                                <input className='new-textinput text-gray p-2 mt-2 w-100' value={ticketData.name || ticketData.organisation} type="text" readOnly />
                            </div>
                            <div className='col-12 col-md-6'>
                                <label className='text-gray font-size-12 custom-font-700 mt-0 mb-0 font-sans'>Email ID <span className="text-danger">*</span></label>
                                <input className='new-textinput text-gray p-2 mt-2 w-100' type="email" value={email} />
                            </div>
                        </div>

                        <div className='row g-3 mt-3'>
                            <div className='col-12'>
                                <label className='text-gray font-size-12 custom-font-700 mt-0 mb-0 font-sans'>Subject <span className="text-danger">*</span></label>
                                <input className='new-textinput text-gray p-2 mt-2 w-100' value={ticketData.subject} type="subject" readOnly />
                            </div>
                        </div>

                        <div className='row g-3 mt-3'>
                            <div className='col-12'>
                                <label className='text-gray font-size-12 custom-font-700 mt-0 mb-0 font-sans'>Described problem <span className="text-danger">*</span></label>
                                <div className='mt-2'>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" className='w-100 h-100 p-3 text-gray   describeproblem  ' value={ticketData.description} readOnly >
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        {/* <div className='row g-3 mt-3'>
                            <div className='col-12 '>
                                {ticketData?.images?.map((image, index) => (
                                    <img key={index} src={image.filepath} alt={`Image ${index}`} className='img-fluid w-100 viewimg mt-2' />
                                ))}
                            </div>
                        </div> */}
                        <div className='w-100 radius-12px col-12  mt-3 '>
                            <div className="d-flex align-items-center justify-content-between ">
                                <div className="d-flex flex-column align-items-center" style={{ gap: "10px" }}>
                                    {
                                        ticketData?.images?.length > 0 && ticketData?.images?.map((image, index) => (
                                            <div className="d-flex justify-content-center align-items-center" key={index}>
                                                <div
                                                    className="d-flex align-items-center radius-10px custom-bg-white-150"
                                                    style={{ width: "300px", height: "60px" ,cursor:'pointer'}}
                                                onClick={() => {
                                                    // dispatch({ type: "SUPPORT_VIEW_DETAILS", payload: image });
                                                    setImageView(image);
                                                    setIsViewImages(true);
                                                }}
                                                >
                                                    <span className="p-2"><img src={uploadimage} alt="uploadimage" style={{ width: "24px", height: "24px" }} /></span>
                                                    <p className='mt-0 mb-0  font-size-12 text-secondary custom-font-500 font-inter'>{image?.filename.replace(/^rixosys\//, '')}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <ViewImageModal
                                setIsViewImages={setIsViewImages}
                                isViewImage={isViewImage}
                                imageView = {imageView}

                            />
                        </div>
                        {admin ? ticketData.assign_to ?
                            <div className='col-12 border-1 border-radius-5 mt-4 d-flex justify-content-between align-items-center'>
                                <div className="p-2 ">
                                    <div className="d-flex align-items-center px-3 py-2 ml-3 bg-grey border-radius-5 ">
                                        <div className={`rounded-circle text-white`}
                                            style={{ width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', backgroundColor: "#03565A" }} >
                                            {team.find(teamMember => teamMember._id === ticketData.assign_to)?.name.charAt(0).toUpperCase()}
                                        </div>
                                        {ticketData.status === 'Closed' ? <div className='text-gray custom-font-500 font-size-12 '>{team.find(teamMember => teamMember._id === ticketData.assign_to)?.name} <ClearIcon className="font-size-14 pointer" onClick={ ()=> swal('',"Ticket Closed",'warning')}/></div> : <div className='text-gray custom-font-500 font-size-12 '>{team.find(teamMember => teamMember._id === ticketData.assign_to)?.name} <ClearIcon onClick={() => removeAgent(ticketData._id)} className="font-size-14 pointer" /></div> }
                                        
                                    </div>
                                </div>
                                {ticketData.status === 'Closed' ? <div className="p-2"><button className=' custom-font-700  p-3 text-custome bg-transparent border-custom border-radius-5' onClick={ ()=> swal('',"Ticket Closed",'warning')}>Change Agent </button></div>:<div className="p-2"><button className=' custom-font-700  p-3 text-custome bg-transparent border-custom border-radius-5' onClick={handleOpen}>Change Agent </button></div>}
                                
                            </div> :
                            <div className='row g-3 mt-3'>
                                <div className='col-12 text-center'>
                                    <button className='assignagent custom-font-700 font-size-16   custom-common-theme-bg-color  text-white' onClick={handleOpen}>Assign Agent</button>

                                </div>
                            </div>
                            : <div className="d-flex  justify-content-center mt-4 mb-2">
                                <button className="mt-4 w-50 p-3 custom-font-500 font-size-16 rounded-2 custom-common-theme-bg-color border-0 text-white" onClick={() => navigate(`/support-deatails/${id}`)}>Start Conversations</button>
                            </div>}



                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className='d-flex justify-content-between align-items-center '>
                                    <h2 className='custom-font-700 font-size-20  font-sans'>Assign Agent</h2>
                                    <h3 className='custom-font-700 font-size-12 font-sans' style={{ cursor: "pointer" }} onClick={handleClose}>Close</h3>
                                </div>

                                <div className="search-btn d-flex flex-row jusify-content-center align-items-center mt-4 " style={{ border: "2px solid  #03565a", borderRadius: "5px" }}>

                                    <input
                                        className="border-0 mt-0 p-0 custom-font-500 "
                                        type="text"
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>

                                <label className="mt-4 custom-font-700 font-size-16 text-gray font-sans">Select People</label>

                                <div style={{ height: '150px', overflowY: 'auto' }}>
                                    {filteredAgents.map(agent => (
                                        <ListGroup.Item key={agent._id} value={agent._id} onClick={() => handleChangeAgent(agent)} className={`d-flex align-items-center no-border mt-3 pointer border-15 ${selectedAgent === agent._id ? 'selected-agent' : ''}`}>
                                            <div className={`rounded-circle text-white }`}
                                                style={{ width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', backgroundColor: "#03565A" }} >
                                                {agent.name.charAt(0).toUpperCase()}
                                            </div>
                                            <div className='text-gray custom-font-500 font-size-12'>{agent.name}</div>

                                        </ListGroup.Item>
                                    ))}

                                </div>

                                <div className="d-flex  justify-content-center mt-4 mb-2">
                                    <button className="mt-4 w-100 p-3 custom-font-500 font-size-16 rounded-2 custom-common-theme-bg-color border-0 text-white" onClick={() => handleAssign()}>Assign</button>
                                </div>

                            </Box>
                        </Modal>
                    </Card>
                </div>

            </div>
        </>
    )
}

export default SupportView
