import React, { useEffect } from 'react'
import FirstCard from './Hompage/Cards/FirstCard/FirstCard'
import SecondCard from './Hompage/Cards/SecondCard/SecondCard'
import ThirdCard from './Hompage/Cards/ThirdCard/ThirdCard'
import FourthCard from './Hompage/Cards/FourthCard/FourthCard'
import { BarChart } from './Hompage/SectionOne/BarChart'
import { LineChart } from './Hompage/SectionOne/LineChart'
import { PieChart } from './Hompage/SectionOne/PieChart'
import { useNavigate } from 'react-router-dom'
       
        

function Homepage() {
  
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem("auth_token");
  //   console.log("Token from localStorage:", token);

  //   if (!token) {
  //     console.log("Token not found. Redirecting to login page.");
  //     navigate("/login");
  //   } else {
  //     console.log("Token found. Proceeding with the component rendering.");
  //   }
  // }, [navigate]);

  // // Rest of your component code



  return (
    <>
     <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-evenly',margin:'40px 0px'}}>
      <div><FirstCard/></div> 
      <div><SecondCard/></div>
      <div><ThirdCard/></div>
      <div><FourthCard/></div>
      </div>
      <div><LineChart/></div>
      <div><PieChart/></div>
     <div><BarChart/></div> 
    </>
  )
}

export default Homepage