

import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Card } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
};

const features = {
    workspaceFunctions: [
        'Book Closure',
        'Standalone financial statements',
        'Console financial statements',
        'Data analytics',
        'Projections (Budget vs fixed)',
        'SEBI Reporting'
    ],
    dataset: [
        'Sales',
        'Purchases',
        'Leases',
        'Investment',
        'Fixed Assets',
        'Receivables',
        'Payables',
        'Loans'
    ],
    accessManagement: [
        'No Access Management',
        'Unrestricted & View only Access',
        'Segregation of Duties'
    ],
    noOfUsers: [
        '5',
        '10',
        '30',
        '50',
        '100'
    ]
};

const SelectFeaturesModal = ({ open, handleClose, onConfirm, selectedFeatures }) => {
    const [checkedItems, setCheckedItems] = useState({});
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        if (selectedFeatures) {
            console.log(selectedFeatures, "selectedFeatures--")
            const newCheckedItems = {};
            for (const category in selectedFeatures) {
                if (selectedFeatures.hasOwnProperty(category)) {
                    newCheckedItems[category] = {};
                    selectedFeatures[category].forEach(item => {
                        newCheckedItems[category][item] = true;
                    });
                }
            }
            setCheckedItems(newCheckedItems);
            setIsChecked(Object.values(newCheckedItems).some(categoryItems => Object.values(categoryItems).some(value => value)));
        }
    }, [selectedFeatures]);



    const handleCheckboxChange = (category, itemName) => {
        let newCheckedItems = { ...checkedItems };

        if (category === 'noOfUsers' || category === 'accessManagement') {
            // Uncheck all other checkboxes in the 'noOfUsers' category
            newCheckedItems[category] = { [itemName]: true };
        } else {
            newCheckedItems = { ...newCheckedItems, [category]: { ...checkedItems[category], [itemName]: !checkedItems[category]?.[itemName] } };
        }

        setCheckedItems(newCheckedItems);
        setIsChecked(Object.values(newCheckedItems).some(categoryItems => Object.values(categoryItems).some(value => value)));
    };

    const handleConfirm = () => {
        const selectedItems = {};
        for (const [category, items] of Object.entries(checkedItems)) {
            selectedItems[category] = Object.keys(items).filter(item => items[item]);
        }

        // Flatten the selectedItems object into an array of selected items for display
        const allSelectedItems = Object.values(selectedItems).flat();
        const displayString = allSelectedItems.join(', ');

        if (onConfirm) {
            onConfirm(selectedItems, displayString);
            console.log(selectedItems, "selectedItems---");
        }

        handleClose();
    };

    const renderCheckboxGroup = (category, items) => (
        <td style={{ border: "none" }}>
             {items.map(item => {
                const isDisabled = item === 'Unrestricted & View only Access';
                return (
                    <div key={item} className='d-flex' style={{ gap: "5px" }}>
                        <input
                            type="checkbox"
                            style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: isDisabled ? 'grey' : 'inherit',
                                cursor: isDisabled ? 'not-allowed' : 'pointer'
                            }}
                            className='mt-1 custom-checkbox'
                            checked={checkedItems[category]?.[item] || false}
                            onChange={() => handleCheckboxChange(category, item)}
                            disabled={isDisabled}
                        />
                        <p className='mt-0 mb-0 text-secondary font-sans font-size-16 custom-font-500'>{item}</p>
                    </div>
                );
            })}
        </td>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Card className='w-100 radius-10px' style={{ height: "500px", border: "2px solid #D6D6D6" }}>
                    <div className='px-3 mt-3'>
                        <table className="table w-100" style={{ border: "none" }}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ border: "none" }} className='text-secondary font-sans font-size-16 custom-font-600'>Workspace Functions</th>
                                    <th scope="col" style={{ border: "none" }} className='text-secondary font-sans font-size-16 custom-font-600'>Dataset</th>
                                    <th scope="col" style={{ border: "none" }} className='text-secondary font-sans font-size-16 custom-font-600'>Access Management</th>
                                    <th scope="col" style={{ border: "none" }} className='text-secondary font-sans font-size-16 custom-font-600'>No of Users</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {renderCheckboxGroup('workspaceFunctions', features.workspaceFunctions)}
                                    {renderCheckboxGroup('dataset', features.dataset)}
                                    {renderCheckboxGroup('accessManagement', features.accessManagement)}
                                    {renderCheckboxGroup('noOfUsers', features.noOfUsers)}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-center mt-auto">
                        <Button
                            variant="contained"
                            className='text-capitalize radius-5px'
                            style={{
                                width: "100px",
                                height: "50px",
                                marginTop: "30px",
                                backgroundColor: isChecked ? "#03565A" : "#E5E7E9",
                                cursor: isChecked ? "pointer" : "not-allowed",
                            }}
                            disabled={!isChecked}
                            onClick={handleConfirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </Card>
            </Box>
        </Modal>
    );
};

export default SelectFeaturesModal;


