import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, Box, Button } from "@mui/material";
import { getAlluser } from "../../../apihandler/apihandler";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import nodata from "../../../Assets/no data.jpg";
import "./../SupportPage/support.css";
import {
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import GppBadIcon from "@mui/icons-material/GppBad";
import SearchIcon from "@mui/icons-material/Search";
import AddCompanyModal from "./AddCompanyModal";
import CompanySpecific from "./CompanySpecific";
import Card from "@mui/material/Card";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ClearIcon from '@mui/icons-material/Clear';

export default function CompanyPage() {
  // modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("yes");
  const [currentCompany, setCurrentCompany] = useState(null);
  const [userId, setUserId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await getAlluser();
      console.log(response, "companies--------");
      if (response.status === 200) {
        setCompanyData(response.data.fetchUser);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const handleOpen = (company) => {
    setCurrentCompany(company);
    console.log(company, "company");
    setUserId(company._id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleModalSubmit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/approvedstatus/${userId}`,
        {
          approved: selectedValue,
        }
      );

      console.log(response, "response----0");

      if (response.status) {
        // swal(`Approval status updated successfully to ${selectedValue}`);
        swal(
          "Success",
          `Approval status updated successfully to ${selectedValue}`,
          "success"
        );
      } else {
        swal("Failed to update approval status");
      }

      setOpen(false);
      await fetchCompanies();
    } catch (error) {
      console.error("Error updating approval status:", error);
      alert("An error occurred while updating the approval status");
      setOpen(false);
    }
  };

  const filteredCompanies = companyData.filter(company => 
    company?.organisation.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company._id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="px-4 py-2">
        <div className="d-flex justify-content-between mt-3">
          <div>
            <p className="mt-0 mb-0 custom-font-600 font-size-20 font-sans">
              Company
            </p>
          </div>
          <div className="d-flex" style={{ gap: "10px" }}>
            <div className="search-btnv2 d-flex flex-row jusify-content-center align-items-center">
              <span>
                <SearchIcon style={{ color: "gray" }} />
              </span>
              <input
                style={{ border: "none" }}
                className="w-100"
                placeholder="Search by Company name or ID"
                value={searchTerm} // Controlled input
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm && (
                  <ClearIcon
                    onClick={() => setSearchTerm("")} // Clear search term
                  />
                )}
            </div>
            <div>
              <Button
                variant="outlined"
                className="font-size-16 custom-font-600 primary-border custom-common-theme-text-color text-capitalize font-sans radius-6px"
                style={{ height: "48px" }}
                onClick={handleOpenModal}
              >
                Add Company
              </Button>
              <AddCompanyModal
                open={openModal}
                handleClose={handleCloseModal}
                alluser={fetchCompanies}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <CircularProgress />
          </div>
        ) : companyData.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center vh-70">
            <img
              src={nodata}
              className="img-fluid"
              style={{ maxWidth: "300px" }}
              alt="No Data"
            />
            <p className="text-secondary fs-4">You have no Company data</p>
            <Button
              style={{
                backgroundColor: "#03565A",
                color: "white",
                padding: "2px 90px",
                fontSize: "1rem",
              }}
              onClick={() => navigate(`/addcompany`)}
            >
              Add New Company
            </Button>
          </div>
        ) : (
          <div className="w-100" style={{ overflowY: "auto" }}>
            <table className="mt-4 tablesupport">
              <thead style={{ backgroundColor: "#F8F8F8" }}>
                <tr>
                  <th
                    className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter break"
                    style={{ width: "15%" }}
                  >
                    COMPANY ID
                  </th>
                  <th
                    className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                    style={{ width: "18%" }}
                  >
                    Activation Date
                  </th>
                  <th
                    className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                    style={{ width: "10%" }}
                  >
                    Organisation Name
                  </th>
                  <th
                    className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                    style={{ width: "10%" }}
                  >
                    Email
                  </th>
                  <th
                    className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                    style={{ width: "10%" }}
                  >
                    State
                  </th>
                  <th
                    className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                    style={{ width: "10%" }}
                  >
                    Subscription
                  </th>
                  <th
                    className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                    style={{ width: "10%" }}
                  >
                    Approved
                  </th>
                  <th
                    className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                    style={{ width: "17%" }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {filteredCompanies.map((det) => {
                  if (!det.parent_id && det.isAdmin === "Yes") {
                    return (
                      <tr className="bg-white">
                        <td
                          className="text-right mt-0 mb-0 text-break font-size-14 custom-font-500 text-gray font-sans"
                          style={{ width: "15%" }}
                        >
                          {det._id}
                        </td>
                        <td
                          className="text-left mt-0 mb-0 font-size-14 custom-font-500 text-gray font-sans"
                          style={{ width: "15%" }}
                        >
                          {moment(det.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td
                          className="text-left mt-0 mb-0 font-size-14 custom-font-500 text-gray font-sans "
                          style={{ width: "15%" }}
                        >
                          {det.organisation}
                        </td>
                        <td
                          className="text-left mt-0 mb-0 font-size-14 custom-font-500 text-gray font-sans"
                          style={{ width: "10%" }}
                        >
                          {det.email}
                        </td>
                        <td
                          className="text-left mt-0 mb-0 font-size-14 custom-font-500 text-gray font-sans"
                          style={{ width: "10%" }}
                        >
                          {det.state}
                        </td>
                        <td
                          className="text-left mt-0 mb-0 font-size-14 custom-font-500 text-gray font-sans"
                          style={{ width: "10%" }}
                        >
                          {det.has_subscribed}
                        </td>
                        <td
                          className="text-left mt-0 mb-0 font-size-14 custom-font-500 text-gray font-sans"
                          style={{ width: "8%" }}
                          onClick={(e) => {
                            handleOpen(det);
                          }}
                        >
                          <span style={{ marginRight: "5px" }}>
                            {det.approved}
                          </span>
                          {det.approved ? (
                            <DriveFileRenameOutlineIcon
                              className="custom-common-theme-text-color"
                              style={{ width: "16px" ,cursor:"pointer"}}
                            />
                          ) : (
                            ""
                          )}
                        </td>

                        <td
                          className="text-left mt-0 mb-0 custom-common-theme-text-color custom-font-600 font-size-14 font-sans"
                          style={{ width: "15%", cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/company-specific/${det._id}`)
                          }
                        >
                          View Details
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="approval-modal-title"
                aria-describedby="approval-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    // width: 200,
                    bgcolor: "background.paper",
                    // border: '2px solid #000',
                    borderRadius: "10px",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      id="approval-modal-title"
                      className="custom-font-600 underline font-sans"
                    >
                      Approval Status
                    </FormLabel>
                    <RadioGroup
                      aria-label="approval-status"
                      name="approval-status"
                      value={selectedValue}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <Button
                      className="font-sans"
                      onClick={handleModalSubmit}
                      variant="contained"
                      style={{ backgroundColor: "#03565A", marginTop: "10px" }}
                    >
                      Update
                    </Button>
                  </FormControl>
                </Box>
              </Modal>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
