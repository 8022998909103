import * as React from "react";
import { useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Popover, Paper, Table, TableBody, TableCell, FormControl, Select, Container, TableContainer, TableHead, TableRow, Typography, TextField, MenuItem, TextareaAutosize, Card } from '@mui/material';
import { postAllPlans, getAllPlans, deletePlans, updatePlans } from '../../../apihandler/apihandler';
import Swal from 'sweetalert2';
import Subcriptionplan from "./Subcriptionplan";
import SubscriptionModal from "./SubscriptionModal";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from "@mui/material/CircularProgress";
// import Button from '@mui/material/Button';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useNavigate } from 'react-router-dom';






const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Plan() {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;


    const [allPlan, setAllPlan] = useState([])
    const [singleElement, setSingleElement] = useState({})
    const [isOpen, setIsOpen] = useState(false);
    const [editAction, setEditAction] = useState("")
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        planName: '',
        planDescription: '',
        planPrice: '',
        planPeriod: '',
        users: '',
        hasApprover: false,
    });


    useEffect(() => {
        getPlan()
    }, []);

    const getPlan = async () => {
        try {
            const res = await getAllPlans();
            if (res.status === 200) {
                setAllPlan(res.data.data)
                console.log(res.data.data);
            }

        } catch (error) {

        }
        finally {
            setLoading(false); // Set loading to false regardless of success or failure
        }
    };



    const handleCreatePlan = () => {
        // Check if planName contains only letters
        if (!/^[a-zA-Z\s]*$/.test(formData.planName)) {
            Swal.fire({
                icon: 'error',
                title: ' Error',
                text: 'Plan Name should only contain letters',
                confirmButtonColor: "#03565A"
            });
            return;
        }

        if (formData.planDescription.trim() === '') {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Plan Description cannot be empty',
                confirmButtonColor: "#03565A"
            });
            return;
        }

        // If planName is valid, proceed with creating the plan
        postAllPlans(formData)
            .then(res => {
                setFormData({ planDescription: "", planName: "", planPeriod: "", planPrice: "", users: "", hasApprover: false });
                handleClose();
                setIsOpen(false);
                getPlan();
                Swal.fire({
                    title: "Success!",
                    text: "Plan created successfully.",
                    icon: "success",
                    confirmButtonColor: "#03565A"
                });
            })
            .catch(error => {
                console.error('Error creating plan:', error);
                setIsOpen(false);
                Swal.fire({
                    icon: 'error',
                    title: "error",
                    text: "Failed to create plan. Please try again later..",
                    confirmButtonColor: "#03565A"
                });
            });
    };



    //delete api
    const deletePlan = async () => {
        handleClose()
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#03565A",
            cancelButtonColor: "#03565A",
            confirmButtonText: "Yes, delete it!"
        });

        if (result.isConfirmed) {
            const res = await deletePlans(singleElement._id);
            if (res.status === 200) {
                handleClose()
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success",
                    confirmButtonColor: "#03565A",
                });
                getPlan();
            }
        }
    };



    const handleClick = (event, element) => {
        setSingleElement(element);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const editPlan = () => {
        handleClose()
        setFormData({ ...formData, planDescription: singleElement.planDescription, planName: singleElement.planName, planPeriod: singleElement.planPeriod, planPrice: singleElement.planPrice, users: singleElement.users, hasApprover: singleElement.hasApprover });
        setEditAction("edit");
        setIsOpen(true);
    }

    const UpdatePlan = () => {
        updatePlans(singleElement._id, formData).then(res => {
            setFormData({ ...formData, planDescription: "", planName: "", planPeriod: "", planPrice: "", users: "", hasApprover: false });
            setEditAction("create");
            setIsOpen(false);
            getPlan();
        }).catch(error => {
            console.log("error=>>>>>>>", error)
        })
    }



    return (

        <>
            <div className="px-4 py-3">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="mt-0 mb-0 font-size-20 custom-font-600 font-sans">Plan</p>
                    <Button
                        variant="outlined"
                        className="d-flex align-items-center primary-border custom-common-theme-text-color font-size-16 custom-font-600 text-capitalize font-sans"
                        style={{ width: "200px", height: "50px" }}
                        onClick={() => navigate(`/edit-plan`)}
                    >
                        <DriveFileRenameOutlineIcon style={{ marginRight: '8px', }} />
                        Add/Edit Plan
                    </Button>
                </div>
                <div className='mt-3'>
                    <Card className='w-100 radius-10px no-shadow ' style={{ border: "1px solid #D6D6D6" }}>
                        <div>
                            <table className="table table-bordered">
                                <thead style={{ backgroundColor: "#F8F8F8" }}>
                                    <tr>
                                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter custom-backgroundColor " >Plan Name</th>
                                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter w-25 custom-backgroundColor" >Description</th>
                                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter w-50 custom-backgroundColor" >Benefits</th>
                                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter custom-backgroundColor" >Price</th>
                                        <th className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter custom-backgroundColor" >Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPlan.map((elem) => {
                                        return (
                                            <>

                                                <tr>
                                                    <td className="text-right">
                                                        <p className='mt-0 mb-0  font-size-16 custom-font-500 text-dark font-sans'>{elem.planName}</p>
                                                    </td>
                                                    <td className="text-left">
                                                        <p className='mt-0 mb-0 font-size-16 custom-font-500  text-dark font-sans'>{elem.planDescription}</p>
                                                    </td>
                                                    <td className="text-left">
                                                        <div className="flex-column">
                                                        <p className="mt-0 mb-0 font-size-12 custom-font-500 text-secondary  font-sans">Access Management	</p>
                                                            {elem.categorizedBenefits && elem.categorizedBenefits.accessManagement && elem.categorizedBenefits.accessManagement.length > 0 ? (
                                                               
                                                                    <p  className="mt-0 mb-0 font-size-16 custom-font-500  text-dark font-sans">{elem.categorizedBenefits.accessManagement.join(',')}</p>
                                                                
                                                            ) : (
                                                                <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">No features available</p>
                                                            )}
                                                        </div>
                                                        <div className="flex-column mt-1">
                                                            <p className="mt-0 mb-0 font-size-12 custom-font-500 text-secondary font-sans">Dataset	</p>
                                                            {elem.categorizedBenefits && elem.categorizedBenefits.dataset && elem.categorizedBenefits.dataset.length > 0 ? (
                                                                 <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">{elem.categorizedBenefits.dataset.join(', ')}</p>
                                                                )
                                                             : (
                                                                <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">No features available</p>
                                                            )}
                                                        </div>
                                                        <div className="flex-column mt-1">
                                                            <p className="mt-0 mb-0 font-size-12 custom-font-500 text-secondary font-sans">Workspace Functions</p>
                                                            {elem.categorizedBenefits && elem.categorizedBenefits.workspaceFunctions && elem.categorizedBenefits.workspaceFunctions.length > 0 ? (
                                                            
                                                                    <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">{elem.categorizedBenefits.workspaceFunctions.join(', ')}</p>
                                                                )
                                                             : (
                                                                <p className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">No features available</p>
                                                            )}
                                                        </div>
                                                        <div className="flex-column mt-1">
                                                            <p className="mt-0 mb-0 font-size-12 custom-font-500 text-secondary font-sans">No of users</p>
                                                            {elem.categorizedBenefits && elem.categorizedBenefits.noOfUsers && elem.categorizedBenefits.noOfUsers.length > 0 ? (
                                                                
                                                                    <p  className="mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans">{elem.categorizedBenefits.noOfUsers.join(',')}</p>
                                                            
                                                            ) : (
                                                                <p className="mt-0 mb-0 font-size-16 custom-font-500  text-dark font-sans">No features available</p>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="text-left">
                                                        <p className='mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans'>₹ {elem.planPrice}</p>
                                                    </td>
                                                    <td className="text-left">
                                                        <p className='mt-0 mb-0 font-size-16 custom-font-500 text-dark font-sans'>{elem.planPeriod}</p>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    {/* <tr>
                                        <td className="text-left">
                                            <p className='mt-0 mb-0  font-size-16 custom-font-500 text-secondary font-sans'>Free</p>
                                        </td>
                                        <td className="text-left">
                                            <p className='mt-0 mb-0 font-size-16 custom-font-500 text-secondary font-sans'>Get your first 7 Days for Free</p>
                                        </td>
                                        <td className="text-left">
                                            <p className='mt-0 mb-0 font-size-16 custom-font-500 text-secondary font-sans'>All Features</p>
                                        </td>
                                        <td className="text-left">
                                            <p className='mt-0 mb-0 font-size-16 custom-font-500 text-secondary font-sans'>₹ 0</p>
                                        </td>
                                        <td className="text-left">
                                            <p className='mt-0 mb-0 font-size-16 custom-font-500 text-secondary font-sans'>7 Days</p>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div>

            </div>
        </>
    );
}

