import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { getTicketById, postNotes, getProfile, reopenTicketStatus, closeTicketStatus,getNotes } from '../../../apihandler/apihandler';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function Viewticket() {
    const navigate = useNavigate()
    const [ticketData, setTicketData] = useState({})
    const [creatorId, setCreatorId] = useState('')
    const [note, setNote] = useState('')
    const [status, setStatus] = useState(true)
    const [update, setUpdate] = useState(true)
    const [chats, setChats] = useState([])

    const Back = () => {
        navigate("../support")
    }

    // const Editbutton=()=>{
    //     navigate("../")
    // }



    const { id } = useParams();
    console.log(id)

    useEffect(() => {
        fetchTicket();
    }, [status]);
    useEffect(() => {
        getProfile();

    }, [])
    const fetchTicket = async () => {

        try {
            const response = await getTicketById(id)
            console.log("get ticket by id show", response)
            if (response.data.data) {
                console.log(response.data.data, "ticket----------------------------------")
                setTicketData(response.data.data)
                setCreatorId(response.data.data.customer_id);
            }
        }
        catch (error) {
            console.log("erro fetching ticket deatails")
        }

    }

    const handleCancelStatus = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/close-status/${id}`,{ 
                customer_id:creatorId

              });
            if (response.status === 200) {
                console.log('successfully updated');
                setStatus(!status)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleReopenStatus = async () => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You want  to Re-open this Ticket!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#03565A",
                cancelButtonColor: "#03565A",
                confirmButtonText: "Yes, Re-Open"
            });
            if (result.isConfirmed) {
                const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/reopen/${id}`,{ 
                    customer_id:creatorId
 
                  });
                if (response.status === 200) {
                    Swal.fire({
                        title: "Re-Opened!",
                        text: "Your ticket has been re-open.",
                        icon: "success",
                        confirmButtonColor: "#03565A",
                    });
                    console.log('successfully updated');
                    setStatus(!status)
                }

            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleCreateNote = async () => {
        const bodydata = {
            ticket_id: id,
            creator_id: creatorId,
            has_internal: 'yes',
            images: 'C:\\fakepath\\invoice-pdf (44).pdf',
            notes: note,
            added_by: 'superadmin'
        }


        try {

            const response = await postNotes(bodydata);
            if (response.status === 200) {
                console.log('Notes created:', response);
                setNote('')
                setUpdate(!update)
            }

        } catch (error) {
            console.log(error, 'error')
        }
    }

    useEffect(() => {
        handleGetNotes();
    }, [update])


    const handleGetNotes = async () => {

        try {
            // const response = await getNotes();
            const response = await getNotes(id)
            console.log(response, 'response>>>>>>>')
            if (response.status === 200) {
                setChats(response.data.data);
            }

        } catch (error) {

        }
    }






    return (
        <>
            <div className='px-3 mt-4'>
                <span className='font-weight-bold fs-6' style={{ color: 'black' }}>Tickets <i class="bi bi-arrow-right"></i> View tickets</span>
                <Card className='px-4' >


                    <div className='mt-4'>

                        <div style={{ display: "flex", gap: "55px" }}>
                            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ color: "black", fontWeight: "500" }}>Title:</p>

                            </div> */}

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ fontWeight: "bold", textAlign: "justify" }}>{ticketData.subject}</p>

                            </div>

                        </div>


                        <div style={{ display: "flex", justifyContent: "space-between" }}>



                            <div style={{ display: "flex", gap: "30px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ color: "black", fontWeight: "500" }}>Type:</p>
                                    <p style={{ color: "black", fontWeight: "500" }}>Priority:</p>
                                    <p style={{ color: "black", fontWeight: "500" }}>Customer:</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p>{ticketData.type}</p>
                                    <p>{ticketData.priority}</p>
                                    <p>{ticketData.organisation}</p>
                                </div>
                            </div>



                            <div style={{ display: "flex", }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ color: "black", fontWeight: "500" }}>Created At:</p>
                                    <p style={{ color: "black", fontWeight: "500" }}>Updated At:</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p>{moment(ticketData.createdAt).format("DD/MM/YYYY")}</p>
                                    <p>{moment(ticketData.updatedAt).format("DD/MM/YYYY")}</p>
                                </div>
                            </div>


                            <div style={{ display: "flex", paddingLeft: "30px" }}>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ color: "black", fontWeight: "500" }}>Added by:</p>
                                    <p style={{ color: "black", fontWeight: "500" }}>Status:</p>

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ marginLeft: '10px' }}>{ticketData.added_by ? ticketData.added_by : 'previously'}</p>
                                    <p>{ticketData.status}</p>

                                </div>

                            </div>


                        </div>

                        <div style={{ display: "flex", }}>
                            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ color: "black", fontWeight: "500" }}>Description:</p>

                            </div> */}

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p>{ticketData.description}</p>

                            </div>

                        </div>

                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingBottom: "20px" }}>
                        <Button variant="contained" style={{ backgroundColor: "#03565A" }} onClick={Back}>BACK</Button>
                        {ticketData.status === 'Close' && (
                            <Button variant="contained" style={{ backgroundColor: "#03565A" }} onClick={() => handleReopenStatus()}>RE-OPEN TICKET</Button>
                        )}
                        {ticketData.status === 'Open' && (
                            <>
                                <Button variant="contained" style={{ backgroundColor: "red" }} onClick={() => handleCancelStatus()}>CLOSE</Button>
                                <Button variant="contained" style={{ backgroundColor: "#03565A" }} onClick={() => navigate(`/support-editticket/${ticketData._id}`)}>EDIT TICKET</Button>
                            </>
                        )}
                        {ticketData.status === 'Reopen' && (
                            <Button variant="contained" style={{ backgroundColor: "red" }} onClick={() => handleCancelStatus()}>CLOSE</Button>
                        )}
                    </div>


                </Card>

                <Card className='mt-5 px-3 pt-4' style={{ height: "250px" }} >
                    <p style={{ color: "black", fontWeight: "500" }}>Conversations</p>


                    <div data-mdb-input-init className="form-outline">
                        <textarea className="form-control" id="textAreaExample" rows="4" placeholder='Enter Your Message' value={note} onChange={(e) => setNote(e.target.value)}></textarea>
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button variant="contained" style={{ backgroundColor: "#03565A", }} onClick={() => handleCreateNote()} >SEND<span style={{ marginLeft: "10px" }}><i class="bi bi-arrow-right-circle" ></i></span></Button>
                    </div>

                </Card>



                <div className=' mt-5' style={{ height: '100%' }}>
                <Card sx={{ height: '100%' }} className='p-4'>
                        {chats.map((chat) => (
                            chat.added_by === 'admin' ?
                                (
                                    <div className="d-flex flex-row justify-content-start mb-4">
                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                                            alt="avatar 1" style={{ width: '45px', height: '100%', marginTop: '25px' }} />
                                        <div>
                                            <sub className='ml-2 text-grey'>{chat.added_by === 'admin' ? 'Myfinalyst' : chat.added_by} {moment(chat.createdAt).format("DD/MM/YYYY")} {moment(chat.createdAt).fromNow()}</sub>
                                            <div className="p-3 ms-3" style={{ borderRadius: "15px", backgroundColor: "rgba(57, 192, 237,.2)", }}>
                                                <p className="small m-1">{chat.notes}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-row justify-content-end">

                                        <div>
                                            <sub className='ml-2 text-grey'>{chat.added_by === 'admin' ? 'Myfinalyst' : chat.added_by} {moment(chat.createdAt).format("DD/MM/YYYY")} {moment(chat.createdAt).fromNow()}</sub>
                                            <div className="p-3 ms-3" style={{ borderRadius: "15px", backgroundColor: "rgba(57, 192, 237,.2)", }}>
                                                <p className="small m-1">{chat.notes}</p>
                                            </div>
                                        </div>

                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp" alt="avatar 1" style={{ width: '45px', height: '100%', marginLeft: "10px" }} />
                                    </div>
                                )))}




                        {/* <div className="d-flex flex-row justify-content-end">

                            <div>
                                <sub className='ml-2 text-grey'>{chat.added_by === 'admin' ? 'Myfinalyst' : chat.added_by} {moment(chat.createdAt).format("DD/MM/YYYY")} {moment(chat.createdAt).fromNow()}</sub>
                                <div className="p-3 ms-3" style={{ borderRadius: "15px", backgroundColor: "rgba(57, 192, 237,.2)", }}>
                                    <p className="small m-1">{chat.added_by}</p>
                                </div>
                            </div>

                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp" alt="avatar 1" style={{ width: '45px', height: '100%', marginLeft: "10px" }} />
                        </div> */}

                    </Card>
                </div>




            </div>
        </>
    )
}