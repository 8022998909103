import axios from 'axios';
const auth = localStorage.getItem("auth_token");

let headers = {
  "x-auth-token": auth,
};

export const getAlluser = async () => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/auth/all-user`, { headers })
}


export const getAlluserById = async (id) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/auth/all-user/${id}`);
}



export const postSignup = async (data) => {
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/signup`, data, { headers })
} 


export const getSignup = async (data) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/signup`, data, { headers })
}

export const postAllPlans = async (data) => {
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/createplan`, data, { headers })
}


export const getAllPlans = async (data) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/getplan`, data, { headers })
}

export const deletePlans = async (id) => {
  return axios.delete(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/${id}`, { headers })
}

export const updatePlans = async (data) => {
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/updateplans`, data, { headers })
}

export const getAllLogModule = async (data) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-logmodule/getlog`, data, { headers })
}

export const postAllLogModule = async (data) => {
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-logmodule/createlog`, data, { headers })
}


export const deleteLogModule = async (id, data) => {
  return axios.delete(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-logmodule/${id}`, data, { headers })
}

export const updateLogModule = async (id, data) => {
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-logmodule/${id}`, data, { headers })
}

export const getTicket = async (data) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/ticketdata`, data, { headers })
} 
export const getTicketById = async (id, data) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/${id}`, data, { headers })
} 

export const postTicket = async (data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/createticket`, data, { headers })
}   

export const updateTicket = async (id, data) => {
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/${id}`, data, { headers })
}


export const getTeam = async (data) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-team/teamdata`, data, { headers })
} 


export const getAlltickets = async () => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/getallticket`, { headers })
} 

export const postTeam = async (data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-team/createteam`, data, { headers })
}   

export const updateTeam = async (id, data) => {
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-team/${id}`, data, { headers })
}

export const deleteTeam = async (id, data) => {
  return axios.delete(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-team/${id}`, data, { headers })
}

export const postNotes = async (data) => {
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/ticket-note/createnote`,data,{ headers })
}

export const getNotes = async (id) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/ticket-note/getnote?ticket_id=${id}`,{ headers })
}

export const closeTicketStatus = async (id,data) => {
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/close-status/${id}`,data,{ headers })
}

export const assigntoAgent = async (data) => {
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/assignagent`,data,{ headers })
}

export const removeAssigntoAgent = async (data) => {
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/removeassign`,data,{ headers })
}

export const reopenTicketStatus = async (id,data) => {
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-ticket/reopen/${id}`,data,{ headers })
}

export const updateComapny = async (id,data) => {
  return axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/auth/company/${id}`,data,{ headers })
}

export const VerifyOtp = async (data) => { 
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/auth/send-otp`,data)
}




// export const updateLogModule = async (id,data) => {
//   return axios.put(`${process.env.REACT_APP_BACKEND_URL}api/v1/logmodule/${id}`,data,{headers})
// }

export const getsubScriptions = async (id) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/payment/get-all-subscriptions?customer_id=${id}`,{headers})
}

export const getInvoiceById = async (id) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/get-all-invoice/${id}`,{headers})
}

export const getProfile = async () => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin-profile/profile`,{headers})
}

export const state = async () => { 
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/seeder/get-state`)
}

export const cities = async () => { 
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/seeder/get-cities`)
}

export const signupV2 = async (data) => { 
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/auth/signupV2`,data)
}

export const signupFromSuperadmin = async (data) => { 
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/signupFromSuperadmin`,data)
}

export const resetPassword = async (data) => { 
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/resetpassword`,data)
}



export const GetSubscriptionPlansDetails = async(plan_id)=>{
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/${plan_id}`,{headers})
}
export const addPlan = async(data)=>{
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/payment/add-plan`,data,{headers})
}
export const addPayment = async(data)=>{
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/payment/add-payment`,data,{headers})
}
export const getPaymentByCustomerId = async(customer_id)=>{
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/payment/get-payment-by-customerid?customer_id=${customer_id}`,{headers})
}