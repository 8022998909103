import React, { useState, useEffect } from 'react';
import { Table, Popover, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Modal, Box, TextField } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteLogModule, getAllLogModule, postAllLogModule, updateLogModule } from '../../../apihandler/apihandler';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 200,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const Logmodule = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [allLog, setAllLog] = useState([]);
    const [singleElement, setSingleElement] = useState({});
    const [editAction, setEditAction] = useState("")
    const [postData, setPostData] = useState({
        moduleName: ""
    });


    // post logmodule data------------
    const handleCreateLog = () => {
        handleCloseModal()
        if (!/^[a-zA-Z\s]*$/.test(postData.moduleName)) {
            Swal.fire({
                title: "Error!",
                text: "Module Name should only contain letters.",
                icon: "error",
                confirmButtonColor: "#03565A"
            });
            return;
        }
        if (postData.moduleName.trim() === '') {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Module Name cannot be empty',
                confirmButtonColor: "#03565A"
            });
            return;
        }
        postAllLogModule(postData)
            .then(res => {
                setPostData({ moduleName: "" });
                handleCloseModal();
                getLogModule();
                Swal.fire({
                    title: "Success!",
                    text: "Log Module created successfully.",
                    icon: "success",
                    confirmButtonColor: "#03565A"
                });
            })
            .catch(error => {
                console.error('Error creating log module:', error);
                handleCloseModal();
                Swal.fire("Error", "Failed to create log module. Please try again later.", "error");
            });
    }

    const [openModal, setOpenModal] = useState(false);
    
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPostData({ ...postData, [name]: value });
    }; 

    useEffect(() => {
        getLogModule(); 
    }, []);            

    // get api call-------------
    const getLogModule = async () => {
        try {
            const res = await getAllLogModule();
            if (res.status === 200) {
                setAllLog(res.data.data);
            }
        } catch (error) {
            console.error('Error fetching log modules:', error);
        }
    }; 


    const handleEdit = () => {
        handleClose()
        setPostData({ ...postData, moduleName: singleElement.moduleName });
        setEditAction("edit");
        handleOpenModal();
    };
      

    const UpdateLogModule = () => {
        handleCloseModal();

        if (!/^[a-zA-Z\s]*$/.test(postData.moduleName)) {
            Swal.fire({
                title: "Error!",
                text: "Module Name should only contain letters.",
                icon: "Error",
                confirmButtonColor: "#03565A"
            });

            return;
        }

        updateLogModule(singleElement._id, postData)

            .then(res => {
                setPostData({ ...postData, moduleName: "" });
                setEditAction("create")
                getLogModule();
                Swal.fire({
                    title: "Success!!",
                    text: "Log Module updated successfully.",
                    icon: "Success!",
                    confirmButtonColor: "#03565A"
                });
                handleCloseModal(); 

            })
            .catch(error => {
                console.error('Error updating log module:', error);
                Swal.fire("Error", "Failed to update log module. Please try again later.", "error");
            });
    };  


    const handleClick = (event, element) => {
        setSingleElement(element);
        setAnchorEl(event.currentTarget);
    }; 

    const handleClose = () => {
        setAnchorEl(null);
    };

    // delete api------------------
    const deleteLog = async () => {
        handleClose()
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#03565A",
            cancelButtonColor: "#03565A",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            const res = await deleteLogModule(singleElement._id);
            if (res.status === 200) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success",
                    confirmButtonColor: "#03565A",

                });
                getLogModule();
            }
        }
    }; 


    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined; 
    
    return (

        <> 
            <Modal 
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 500 }}>
                    <div>
                        <label style={{ fontSize: "20px" }}>Module Name</label>
                        <br />
                        <TextField
                            name="moduleName" 
                            onChange={handleChange}
                            value={postData.moduleName} 
                            sx={{ 
                                width: 500,
                                maxWidth: '100%',
                                marginTop: "10px"
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", gap: "20px", marginLeft: "65%", paddingTop: "30px" }}>
                        <Button style={{ backgroundColor: "rgb(3, 86, 90)", color: "white" }} onClick={() => setOpenModal(false)}>CANCEL</Button>
                        <Button style={{ backgroundColor: "rgb(3, 86, 90)", color: "white" }}
                            onClick={() => { 
                                if (editAction === "edit") { 
                                    UpdateLogModule();        
                                } else {
                                    handleCreateLog();
                                } 
                            }}
                        >{editAction === "edit" ? "Update" : "Create"}</Button>
                    </div> 
                </Box>
            </Modal>

            {/* Displaying log modules */}
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '30px' }}> 
                <Typography variant='h5'>All Log Module</Typography>
                <Button style={{ backgroundColor: "rgb(3, 86, 90)", color: "white" }} onClick={handleOpenModal}>Add LOG MODULE</Button>
            </div>
            <div style={{ padding: '30px' }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#DDDDDD79' }}>
                            <TableRow>

                                <TableCell align="center">Module Name</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allLog?.map((det) => {
                                return (
                                    <TableRow key={det._id}>
                                        <TableCell align="center">{det.moduleName}</TableCell>
                                        <TableCell align="center">
                                            <MoreVertIcon onClick={(e) => handleClick(e, det)} />
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                }}
                                            >
                                                <Typography sx={{ p: 2 }}>
                                                    <span style={{ cursor: "pointer" }} onClick={() => handleEdit(det)}>Edit</span>
                                                    <br />
                                                    <span style={{ cursor: "pointer" }} onClick={() => deleteLog(det)}>Delete</span>
                                                </Typography>
                                            </Popover>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default Logmodule;
