import { useState, useEffect } from 'react';
// import "./support.css"
import Card from '@mui/material/Card';
import "./support.css"
import Switch from '@mui/material/Switch';
import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Height } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Form, ListGroup } from 'react-bootstrap';
import { getTicket, getAlltickets, getTeam, assigntoAgent, closeTicketStatus, getProfile, getNotes } from '../../../apihandler/apihandler';
import moment from 'moment';
import swal from 'sweetalert';
import { useRef } from 'react';
import Loading from './Loading';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    // height:200,
    borderRadius: "10px",
    bgcolor: 'background.paper',
    border: 'none', // Removed border
    boxShadow: 24,
    p: 4,
};


const SupportPage = () => {

    const [team, setTeam] = useState([])
    const [ticket, setTicket] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [ticketId, setTicketId] = useState('')
    const [agentname, setAgentname] = useState('');
    const [update, setUpdate] = useState(false)
    const [admin, setAdmin] = useState(true)
    const [searchteam, setSearchTeam] = useState('')
    const [open, setOpen] = React.useState(false);
    const handleOpen = (ticket_id) => {
        setOpen(true);
        setTicketId(ticket_id);
    }
    const handleClose = () => setOpen(false);
    const [searchTerm, setSearchTerm] = useState('');
    const ref = useRef();


    const navigate = useNavigate()

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getAgent();
        getSuperadminUser();
    }, []);


    useEffect(() => {
        fetchTickets();
    }, [update]);



    const fetchTickets = async () => {
        try {
            const response = await getAlltickets();
            const fetchedTickets = response.data.tickets || response.data.data || [];
            console.log(fetchedTickets, "fetchedTickets--")
            setTicket(fetchedTickets);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.error || "An error occurred");
            setLoading(false);
        }
    };



    const getAgent = async () => {
        try {
            const res = await getTeam();
            console.log(res, "resteam")
            setTeam(res.data.data)

        } catch (error) {
            console.log(error)
        }
    }

    const agents = [
        { name: 'Varun', initial: 'V', },
    ];

    const filteredAgents = team.filter(agent =>
        agent.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleChangeAgent = (agent) => {
        console.log(agent);
        setSelectedAgent(agent._id);
        setAgentname(agent.name)
        // Perform any additional actions with the selected agent
    };


    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setSearchTerm('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const handleAssign = async () => {
        try {

            const data = {
                id: ticketId,
                agent_id: selectedAgent
            }
            console.log(data, "data----->>")
            const res = await assigntoAgent(data);
            // console.log(ticketId,"ticket_id-")

            console.log(res, "assignagent--");
            swal("", "Successfully Assigned your Ticket", "success")
            handleClose();
            setUpdate(!update);
            setSearchTerm('')

        } catch (error) {
            console.log(error);
        }
    }


    const getSuperadminUser = async () => {
        try {

            const res = await getProfile();
            console.log(res, "user----")
            if (res.status === 200) {

                if (res.data.profile.isadmin === 'no') {

                    setAdmin(false)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getnotes = async (id) => {
        try {
            const res = await getNotes(id);
            console.log(res, "----------res");

            if (!admin) {
                if (res.data.data.length > 0) {
                    navigate(`/support-deatails/${id}`);
                } else {
                    navigate(`/support-viewdeatails/${id}`);
                }
            } else {
                navigate(`/support-viewdeatails/${id}`);
            }
        } catch (error) {
            console.log("Error fetching notes:", error);
        }
    };








    return (
        <>   {loading ? <Loading/> : <div className="px-4 py-4">

                <div className='mt-3'>
                    <div className='d-flex justify-content-between align-items-center' >
                        <div><h5 className='mt-0 mb-0   font-size-20 font-sans custom-font-700' >Support Request</h5></div>
                        <div>
                            <p className='mt-0 mb-0  font-size-16   font-sans custom-font-600 font-sans'>Auto Assign:
                                <Switch
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}

                                />

                            </p>
                        </div>
                    </div>
                    <div style={{ overflowY: "auto" }}>
                        <table className='mt-4 tablesupport'>
                            <thead style={{ backgroundColor: "#F8F8F8" }}>

                                <tr>
                                    <th className="text-left text-uppercase text-gray font-size-12 custom-font-600 font-inter" style={{ Width: '11%', }}>Req ID</th>
                                    <th className="text-left text-uppercase text-gray font-size-12 custom-font-600 font-inter" style={{ width: '18%' }}>Shot description</th>
                                    <th className="text-left text-uppercase text-gray font-size-12 custom-font-600 font-inter" style={{ width: '15%' }}>Open Date</th>
                                    <th className="text-left text-uppercase text-gray font-size-12 custom-font-600 font-inter" style={{ width: '15%' }}>last updated date</th>
                                    <th className="text-left text-uppercase text-gray font-size-12 custom-font-600 font-inter" style={{ width: '13%' }}>Status</th>
                                    {admin && <th className="text-left text-uppercase text-gray font-size-12 custom-font-600 font-inter" style={{ width: '17%' }}>Assign</th>}
                                    <th className="text-left text-uppercase text-gray font-size-12 custom-font-600 font-inter" style={{ width: '11%' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {ticket.map((ticket, index) => {
                                    return (
                                        <tr key={ticket._id}>
                                            <td className="mt-0 mb-0 font-size-16 custom-font-500 text-gray font-sans text-break  text-right "  >
                                                {48454555 + index}
                                            </td>
                                            <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 text-gray font-sans " >
                                                {ticket.subject}
                                            </td>
                                            <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 text-gray font-sans " >
                                                {moment(ticket.createdAt).format('DD/MM/YYYY')}
                                            </td>
                                            <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 text-gray font-sans ">
                                                -
                                            </td>
                                            <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 text-gray font-sans " >
                                                {ticket.status}
                                            </td>
                                            {admin && <td className="text-left mt-0 mb-0 font-size-16 custom-font-500 text-gray font-sans " >
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div className='d-flex'>

                                                        {team.find(teamMember => teamMember._id === ticket.assign_to) ? (
                                                            <div className={`rounded-circle text-white`} style={{ width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', backgroundColor: "#03565A" }}>
                                                                {team.find(teamMember => teamMember._id === ticket.assign_to).name.charAt(0).toUpperCase()}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}

                                                        <span className='mt-0 mb-0 font-size-16 custom-font-500 text-gray font-sans'>{team.find(teamMember => teamMember._id === ticket.assign_to) ? team.find(teamMember => teamMember._id === ticket.assign_to).name : "-Not assigned"}</span>
                                                    </div>

                                                    <span className='arrow pointer'><ExpandMoreIcon onClick={() => handleOpen(ticket._id)} /></span>
                                                    <Modal
                                                        // keepMounted
                                                        open={open}
                                                        onClose={handleClose}

                                                    >
                                                        <div className="dropdown border-0 bg-white" ref={ref}>
                                                            <div className='border  '>
                                                                <div className="dropdown-header  mt-3 d-flex  align-items-center">
                                                                    <div className="avatar text-white">K</div>
                                                                    <span><input type='text' placeholder='search' className='border-0 custom-font-500 font-size-12' value={searchTerm} onChange={(e)=>setSearchTerm(e.target.value)}/></span>
                                                                </div>
                                                            </div>

                                                            <div className="text-gray custom-font-700 font-size-12 mt-4 pb-2" >Select People</div>
                                                            <div style={{ height: '150px', overflowY: 'auto' }}>
                                                                {filteredAgents.map(agent => (
                                                                    <ListGroup.Item key={agent._id} value={agent._id} onClick={() => handleChangeAgent(agent)} className={`d-flex align-items-center no-border mt-3 pointer border-15 ${selectedAgent === agent._id ? 'selected-agent' : ''}`}>
                                                                        <div className={`rounded-circle text-white }`}
                                                                            style={{ width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', backgroundColor: "#03565A" }} >
                                                                            {agent.name.charAt(0).toUpperCase()}
                                                                        </div>
                                                                        <div className='text-gray custom-font-500 font-size-12'>{agent.name}</div>

                                                                    </ListGroup.Item>
                                                                ))}

                                                            </div>
                                                            <button className="mt-4 w-100 p-3 rounded-2 custom-common-theme-bg-color border-0  custom-font-500 font-size-16 text-white" onClick={() => handleAssign()}>Assign</button>
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </td>}

                                            <td className="text-left mt-0 mb-0 font-size-16 custom-font-700 custom-common-theme-text-color font-sans " style={{ cursor: "pointer" }} >
                                                <span onClick={() => getnotes(ticket._id)}>View Details</span>{admin && ticket.assign_to && <span onClick={() => navigate(`/support-deatails/${ticket._id}`)}>/View Chat</span>}
                                            </td>


                                        </tr>
                                    )


                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>}
            





        </>


    );
};

export default SupportPage;
