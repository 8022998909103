import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
const SettingPage = () => {

  const history= useNavigate()
  return (
      <Button style={{ backgroundColor: "rgb(3, 86, 90)", color: "white",marginTop:"20px",marginLeft:"20px" }} onClick={()=>history("/logmodule")}  >LOG MODULE</Button>
  );  
};

export default SettingPage; 
