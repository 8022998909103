import { BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Bodysection/Navbar';
import CustomRouter from './Components/Route/Route';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <CustomRouter/>
      </BrowserRouter>
    </div>
  );
}

export default App;
