import React, { useState, useEffect } from 'react';
import { Box, Button, Card } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { addPlan, getsubScriptions, addPayment } from '../../../apihandler/apihandler';
import swal from 'sweetalert';
import Swal from 'sweetalert2';


const PaymentModal = ({ selectedPlan, handleClosed, getsubscriptions, handleClose }) => {
    console.log(selectedPlan,"selectedPlan----->")
    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState('');
    const [initialPeriod, setInitialPeriod] = useState('');
    const [subscription, setSubscriptions] = useState([]);

    const { id } = useParams();
    console.log(id, "id--->")

    useEffect(() => {
        if (selectedPlan && selectedPlan.planPeriod) {
            setSelectedValue(selectedPlan.planPeriod);
            setInitialPeriod(selectedPlan.planPeriod);
        }
    }, [selectedPlan]);
    console.log(selectedPlan);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };


    const calculatePeriodPrice = (planPrice, initialPeriod, selectedPeriod) => {
        let baseMonthlyPrice;

        switch (initialPeriod.toLowerCase()) {
            case 'weekly':
                baseMonthlyPrice = (planPrice * 4); // Approximate 4 weeks per month
                break;
            case 'monthly':
                baseMonthlyPrice = planPrice;
                break;
            case 'quarterly':
                baseMonthlyPrice = planPrice / 3;
                break;
            case 'half yearly':
                baseMonthlyPrice = planPrice / 6;
                break;
            case 'yearly':
                baseMonthlyPrice = planPrice / 12;
                break;
            default:
                baseMonthlyPrice = planPrice;
                break;
        }

        let calculatedPrice;

        switch (selectedPeriod.toLowerCase()) {
            case 'weekly':
                calculatedPrice = baseMonthlyPrice / 4; // Approximate 4 weeks per month
                break;
            case 'monthly':
                calculatedPrice = baseMonthlyPrice;
                break;
            case 'quarterly':
                calculatedPrice = baseMonthlyPrice * 3;
                break;
            case 'half yearly':
                calculatedPrice = baseMonthlyPrice * 6;
                break;
            case 'yearly':
                calculatedPrice = baseMonthlyPrice * 12;
                break;
            default:
                calculatedPrice = baseMonthlyPrice;
                break;
        }

        return calculatedPrice;
    };

    // const getsubscriptions = async () => {
    //     try {
    //         const user_id = id
    //         const res = await getsubScriptions(user_id);
    //         console.log(res, 'all subscriptions -------->');
    //         setSubscriptions(res.data);
    //     } catch (error) {
    //         console.error('Error fetching subscriptions:', error);
    //     }
    // }

    const calculateSubscriptionEndDate = (startDate, period) => {
        const date = new Date(startDate);
        switch (period.toLowerCase()) {
            case 'weekly':
                date.setDate(date.getDate() + 7);
                break;
            case 'monthly':
                date.setMonth(date.getMonth() + 1);
                break;
            case 'quarterly':
                date.setMonth(date.getMonth() + 3);
                break;
            case 'half yearly':
                date.setMonth(date.getMonth() + 6);
                break;
            case 'yearly':
                date.setFullYear(date.getFullYear() + 1);
                break;
            default:
                break;
        }
        return date;
    };

    const addSubscriptionPlan = async (email) => {
        const startDate = new Date();
        const endDate = calculateSubscriptionEndDate(startDate, selectedValue || initialPeriod);
        const data = {
            user_id: id,
            plan_price: calculatedPrice,
            users: email?.users,
            plan_id: email?._id,
            plan_name: email?.planName,
            subscription_start_date: new Date(),
            subscription_end_date: endDate,
            benefits:email?.benefits,
            categorizedBenefits:email?.categorizedBenefits
        }
        const res = await addPlan(data)
        if (res.status === 200) {
            await addPayments();
            Swal.fire({
                title: "Success!",
                text: "Your Plan Activated Successfully",
                icon: "success",
                confirmButtonColor: "#03565A"
            })
            handleClosed();
            getsubscriptions();

        }
    }

    const addPayments = async () => {
        const data = {
            customer_id: id
        }
        const res = await addPayment(data)
        if (res.status === 200) {
            //   getAllPayments();
        }
    }

    const calculatedPrice = calculatePeriodPrice(selectedPlan.planPrice, initialPeriod, selectedValue);
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1000,
                    height: 550,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '10px',
                }}
            >
                <div>
                    <div className='d-flex justify-content-between p-4  top-redious' style={{ backgroundColor: "#F1F2F5", height: "75px" }}>
                        <p className='mt-0 mb-0 pointer' onClick={() => handleClose()}><ArrowBackIcon /></p>
                        <p className='mt-0 mb-0 custom-font-600 font-size-20 text-dark font-sans'>Payment</p>
                        <h6 className='mt-0 mb-0 text-dark custom-font-600 font-size-16 font-sans pointer' onClick={() => handleClosed()}>Close</h6>
                    </div>
                    <div className='px-4 py-4'>
                        <div className='d-flex' style={{ gap: "10px" }}>
                            <Card className='radius-10px w-25 primary-border ' style={{ height: "360px", backgroundColor: "#03565A33", }}>
                                <div className='px-3 mt-2'>
                                    <p className='mt-0 mb-0 font-sans custom-font-600 font-size-16 text-dark'>{selectedPlan.planName}</p>

                                    <h4 className='font-size-20 custom-font-600 mt-2'>₹ {selectedPlan.planPrice}</h4>
                                    <div className='d-flex  mt-2' style={{ gap: "5px" }}>
                                        {/* <i className="bi bi-check-circle" style={{ width: "15px", height: "15px" }}></i> */}
                                        <p className='mt-0 mb-0 font-sans custom-font-600  text-dark font-size-12 mt-1'>{selectedPlan.planDescription}</p>
                                    </div>
                                    <span className='custom-font-600 mt-1'>Features</span>:
                                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        {Object.entries(selectedPlan.categorizedBenefits).map(([category, benefits], index) => (
                                        <div key={index}>
                                            <span className='custom-font-600 font-size-14'>{category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}</span>
                                            {benefits.map((benefit, benefitIndex) => (
                                                <div className='d-flex mt-1' style={{ gap: '5px' }} key={benefitIndex}>
                                                    <i className="bi bi-check-circle" style={{ width: '15px', height: '15px' }}></i>
                                                    <p className='mt-0 mb-0 font-sans font-size-12 mt-1'>
                                                        {benefit}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    ))}</div>
                                </div>
                            </Card>
                            <Card className='radius-10px no-shadow w-75 gray-border' style={{ backgroundColor: "#F8F8F8", height: "360px", }}>
                                <div className='px-3 mt-2'>
                                    <div><p className='mt-0 mb-0 custom-font-600 font-size-16 font-sans'>Duration:</p></div>
                                    <div className='d-flex' style={{ gap: '10px' }}>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={selectedValue}
                                            name="radio-buttons-group"
                                            onChange={handleChange}
                                            row
                                        >
                                            {['weekly', 'monthly', 'quarterly', 'half yearly', 'yearly'].map((value) => (
                                                <FormControlLabel
                                                    key={value}
                                                    value={value}
                                                    control={<Radio />}
                                                    label={value}
                                                    className={`font-sans  custom-font-600 font-size-14 ${selectedValue === value ? 'text-black' : 'text-gray'}`}
                                                    style={{ fontWeight: selectedValue === value ? 'bold' : 'normal' }}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </div>


                                    <div className='d-flex flex-column mt-2 w-25'>
                                        <h3 className='font-size-14 custom-font-600 mt-0 mb-0 text-dark font-sans  custom-font-500 font-size-14'>Price:</h3>
                                        {/* <input className='new-textinput2 p-2 mt-2' placeholder="MyFinalyst" type="text" value={selectedPlan.planPrice} /> */}
                                        <Card className='no-shadow gray-border radius-6px' style={{ width: "300px", height: "40px" }}>
                                            <p className='mt-0 mb-0 p-2 font-sans '> {selectedPlan.planPrice}</p>
                                        </Card>
                                    </div>
                                </div>
                                <div className='px-3 mt-3'>
                                    <Card className='radius-10px no-shadow gray-borderColor'>
                                        <div>
                                            <div className='px-3 mt-2'>
                                                <p className='mt-0 mb-0 font-sans font-size-16 custom-font-600 text-dark '>Payment:</p>
                                            </div>
                                            <div className='px-3 mt-2'>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mt-0 mb-1 font-sans font-size-16 custom-font-500 Table-headerText' >Sub Total:</p>
                                                    <p className='mt-0 mb-1 font-sans font-size-16 custom-font-600'>₹ {calculatedPrice}</p>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='mt-0 mb-1 font-sans font-size-16 custom-font-500 Table-headerText ' >GST:</p>
                                                    <p className='mt-0 mb-1 font-sans custom-font-600 text-dark font-size-16 custom-font-600'>₹ 20</p>
                                                </div>
                                            </div>
                                            <div className='px-3'><hr /></div>
                                            <div className='d-flex justify-content-between px-3'>
                                                <p className='mt-0 mb-1 font-sans custom-font-600 font-size-16 Table-headerText' >Total:</p>
                                                <p className='mt-0 mb-1 font-sans custom-font-600 font-size-16'>₹ {calculatedPrice + 20}</p>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Card>
                        </div>
                        <div className='d-flex justify-content-end mt-4 mb-3'>
                            <Button variant="contained" className='text-capitalize radius-6px  custom-common-theme-bg-color font-sans custom-font-600 font-size-16' style={{ width: "180px", height: "50px" }} onClick={() => addSubscriptionPlan(selectedPlan)}>Activate Plan</Button>
                            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <SelectcustomModal handleClose={handleClose} />
            </Modal> */}
                        </div>
                    </div>
                </div>
            </Box></>
    )
}

export default PaymentModal