import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Card from "@mui/material/Card";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { ImportContacts } from "@mui/icons-material";
import OverviewSVGComponent2 from "../../../SVGComponents/OverviewSVGComponent2";
import DownloadIcon from "../../../SVGComponents/DownloadIcon";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import SelectPlanModal from "./SelectplanModal";
import Swal from "sweetalert2";
import axios from "axios";
import { useParams } from "react-router-dom";
import LockResetIcon from '@mui/icons-material/LockReset';
import {
  state,
  cities,
  addPlan,
  addPayment,
  getAllPlans,
  resetPassword,
  updateComapny,
  getInvoiceById,
  getAlluserById,
  getsubScriptions,
  getPaymentByCustomerId,
  GetSubscriptionPlansDetails,
} from "../../../../src/apihandler/apihandler";
import moment from "moment";
import swal from "sweetalert";

// import SelectPlanModal from './SelectPlanModal';

const CompanySpecific = () => {
  const [opens, setOpens] = useState(false);

  const handleOpened = () => {
    setOpens(true);
    planhandler();
  };
  const handleClosed = () => setOpens(false);
  const [isFocused, setIsFocused] = useState(false);
  const emails = ["username@gmail.com", "user02@gmail.com"];
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState("");
  const [payment, setPayment] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [cancellationInitiated, setCancellationInitiated] = useState(false);
  const [plans, setPlans] = useState([]);
  const [custId, setcustId] = useState("");
  const [plandetails, setPlandetails] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [edit, setEdit] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [amount, setAmount] = useState(0);
  const [password, setPassword] = useState("");
  const [allstate, setStateData] = useState([]);
  const [allcities, setCitiesData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    fetchCompany();
    getsubscriptions();
    getInvoice();
    getAllPayments();
  }, []);

  const data = {
    organisation: "",
    email: "",
    state: "",
    city: "",
  };

  const [companydata, setCompanydata] = useState(data);

  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };

  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };

  function handleChange(field, value) {
    setCompanydata({ ...companydata, [field]: value });
  }

  const handleStateChange = (e, value) => {
    setSelectedState(value);
    handleChange("state", value);
  };

  const handleCityChange = (e, value) => {
    setSelectedCity(value);
    handleChange("city", value, e);
  };

  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setSelectedState([]);
    }
  };

  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setSelectedCity([]);
    }
  };

  // const handleInputChange = (event, value) => {
  //   if (value && value.length > 3) {
  //     onInputChange(event, value);
  //   } else {
  //     onInputChange(event, "");
  //   }
  // };

  // const handleInputChangeCity = (event, value) => {
  //   if (value && value.length > 3) {
  //     onInputChangecity(event, value);
  //   } else {
  //     onInputChangecity(event, "");
  //   }
  // };

  useEffect(() => {
    setCompanydata({
      organisation: companyData.organisation,
      email: companyData.email,
      state: companyData.state,
      city: companyData.city,
    });
  }, [companyData]);

  useEffect(() => {
    // Initialize state with companyData
    setSelectedState(companyData.state);
    setSelectedCity(companyData.city);
  }, [companyData]);

  const updatecompanydata = async () => {
    try {
      const updatedata = await updateComapny(id, companydata);
      console.log(updatedata, "updatedata----");
      if (updatedata.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Company Updated successfully",
          icon: "success",
          confirmButtonColor: "#03565A",
        });
        await fetchCompany();
        setEdit(false);
      }
    } catch (error) {
      swal("", error, "danger");
    }
  };

  const getsubscriptions = async () => {
    try {
      const user_id = id;
      const res = await getsubScriptions(user_id);
      console.log(res, "all subscriptions -------->");
      setSubscriptions(res.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const cancelUser = async () => {
    try {
      const config = {
        headers: {
          _id: id, // Assuming id is defined somewhere in your frontend code
        },
      };
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}api/v1/auth/login-cancel`,
        {},
        config
      );
      console.log(res, "response for cancel user");
    } catch (error) {
      console.error(error);
    }
  };

  const getplanDetails = async (planid) => {
    try {
      const response = await GetSubscriptionPlansDetails(planid);
      console.log(response.data.data.planPrice, "------------");
      if (response.status === 200) {
        setPlandetails(response.data.data);
      }
    } catch (error) { }
  };

  const handleCancelUser = async () => {
    try {
      // Show confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Once cancelled, User will not be able to login again!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel login!",
      });

      // If user confirms
      if (result.isConfirmed) {
        setCancellationInitiated(true);
        await cancelUser();
        Swal.fire("Cancelled!", "Login has been cancelled.", "success");
      }
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Error",
        "Failed to cancel login. Please try again later.",
        "error"
      );
    }
  };

  const getInvoice = async () => {
    const res = await getInvoiceById(id);
    console.log(res, "resid---");
    if (res.status === 200) {
      setInvoices(res.data.data);
    } else {
      console.log("error");
    }
  };
  const planhandler = async () => {
    try {
      const res = await getAllPlans();
      if (res.status === 200) {
        console.log(res, "response>>>>>>");
        setPlans(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
    // finally {
    //     setLoading(false); // Set loading to false regardless of success or failure
    // }
  };

  const fetchCompany = async () => {
    try {
      const response = await getAlluserById(id);
      console.log("Fetched user data:", response);
      if (response.status === 200) {
        console.log(response.data.user, "user--------------------");
        setCompanyData(response.data.user);
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  // const handleClickOpen = () => {
  //     setOpen(true);
  //     planhandler();
  // };

  // const handleClose = (value) => {
  //   setOpen(false);
  //   setSelectedValue(value);
  //   getplanDetails(value);
  //   checkoutHandler(value)
  // };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    getplanDetails(value)
      .then(() => {
        // checkoutHandler(value);
      })
      .catch((error) => {
        console.error("Error fetching plan details:", error);
      });
  };

  const generateOrderId = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let orderIds = "";
    for (let i = 0; i < 10; i++) {
      orderIds += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return orderIds;
  };

  // const checkoutHandler = async (value)=>{
  //   const bodyData = {
  //       plan_price:plandetails.planPrice,
  //       customer_id:id,
  //       subscription_start_date: new Date(),
  //       subscription_end_date: new Date(new Date().setMonth(new Date().getMonth() + 1)) ,
  //       plan_id: value,
  //       plan_name:plandetails.planName,
  //       // billingDetails,
  //       // shippingDetails,
  //       receipt:generateOrderId(),
  //   };

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/createpaymentOrder`,bodyData);
  //     console.log(response);
  //     setOrderId(response.data.id);
  //     setAmount(response.data.amount);

  //     // Open Razorpay modal
  //     // openRazorpayModal(response.data.id);
  //   } catch (error) {
  //     console.error('Failed to create order:', error);
  //   }
  // };

  const calculateSubscriptionEndDate = (startDate, period) => {
    const date = new Date(startDate);
    switch (period) {
      case "monthly":
        date.setMonth(date.getMonth() + 1);
        break;
      case "quarterly":
        date.setMonth(date.getMonth() + 3);
        break;
      case "yearly":
        date.setFullYear(date.getFullYear() + 1);
        break;
      default:
        break;
    }
    return date;
  };

  // const addSubscriptionPlan = async (email) => {
  //     const data = {
  //         user_id: id,
  //         plan_price: email?.planPrice,
  //         users: email?.users,
  //         plan_id: email?._id,
  //         plan_name: email?.planName,
  //         subscription_start_date: new Date(),
  //         subscription_end_date: calculateSubscriptionEndDate(new Date(), email.planPeriod)
  //     }
  //     const res = await addPlan(data)
  //     if (res.status === 200) {
  //         getsubscriptions();
  //     }
  // }

  // const addPayments = async () => {
  //     const data = {
  //         customer_id: id
  //     }
  //     const res = await addPayment(data)
  //     if (res.status === 200) {
  //         getAllPayments();
  //     }
  // }

  const getAllPayments = async () => {
    try {
      const customer_id = id;
      const res = await getPaymentByCustomerId(customer_id);
      if (res.status === 200) {
        setPayment(res.data.getpayment);
      }
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  console.log(payment, "====-=-=-=-=");

  const generatePassword = (length = 9) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@";
    let generatedPassword = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      generatedPassword += charset[randomIndex];
    }
    return generatedPassword;
  };

  const handleResetPassword = async () => {
    const newPassword = generatePassword();

    const data = {
      tempPassword: newPassword,
      userId: id,
    };
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Once Reset, User will not be able to login with previous password!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#03565A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reset password!",
      });

      if (result.isConfirmed) {
        await resetPassword(data);
        Swal.fire(
          "Reset!",
          "Password has been reset.Check your mail for your new password!",
          "success"
        );
        await fetchCompany();
      }
    } catch (error) {
      console.error("Error resetting password", error);
    }
  };

  // const navigate = useNavigate();
  return (
    <>
      <div className="px-4 py-4">
        <div className="row">
          <div className="col-sm-4">
            {/* <Card className='w-100 radius-10px custom-border'>
                            <div className='p-3'>
                                <div className='d-flex justify-content-between'>
                                    <p className='mt-0 mb-0 font-size-20 custom-font-600 text-dark font-sans'>Company Details:</p>
                                    <div className='d-flex' style={{ gap: "5px" }}>
                                        {edit ? "" : <DriveFileRenameOutlineIcon className='custom-common-theme-text-color' />}
                                        {edit ? (<p className='mt-0 mb-0 font-size-16 custom-font-500 custom-common-theme-text-color pointer' onClick={() => updatecompanydata()}>Update</p>) : <p className='mt-0 mb-0 font-size-16 custom-font-500 custom-common-theme-text-color pointer font-sans' onClick={() => setEdit(true)}>Edit</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='p-3'>
                                <div className='d-flex flex-column mb-3'>
                                    <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-gray font-sans'>Organization Name:</h3>
                                    {edit ? <input className='new-textinput p-2 mt-2 w-100 ' type="text" value={companydata.organisation} onChange={(e) => setCompanydata({ ...companydata, organisation: e.target.value })} /> : <input className='new-textinput p-2 mt-2 w-100 font-sans' placeholder="MyFinalyst" type="text" value={companyData.organisation} readOnly />}
                                </div>
                                <div className='d-flex flex-column mb-3'>
                                    <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-gray font-sans'>Organization Email Id:</h3>
                                    {edit ? (<input className='new-textinput p-2 mt-2 w-100 ' type="text" value={companydata.email} onChange={(e) => setCompanydata({ ...companydata, email: e.target.value })} />) : (<input className='new-textinput p-2 mt-2 w-100' placeholder="MyFinalyst@Gmail.com" type="text" value={companyData.email} readOnly />)}
                                </div>
                                {edit ? (<div className='d-flex' style={{ gap: "10px" }}>
                                    <div className='d-flex flex-column mb-2 w-50'>
                                        <h3 className='font-size-12 custom-font-600 mt-2 mb-0  font-sans text-gray'>Select State:</h3>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            // options={allstate?allstate:[]}
                                            options={!allstate ? [] : allstate}
                                            onChange={(e, value) => handleChange("state", value)}
                                            onInputChange={onInputChange}
                                            sx={{ width: 205, backgroundColor: "white", marginTop: 1 }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label=<LocationOnIcon />
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: {
                                                            border: "none",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='d-flex flex-column mb-3 w-50'>
                                        <h3 className='font-size-12 custom-font-600 mt-2 mb-0 text-gray font-sans '>Select City:</h3>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            // options={allcities?allcities:[]}
                                            options={!allcities ? [] : allcities}
                                            onChange={(e, value) => handleChange("city", value, e)}
                                            onInputChange={onInputChangecity}
                                            sx={{ width: 215, backgroundColor: "white", marginTop: 1 }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label=<LocationOnIcon />
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: {
                                                            border: "none",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>) : (<div className='d-flex mt-1' style={{ gap: "10px" }}>
                                    <div className='d-flex flex-column mb-3 w-100'>
                                        <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-gray font-sans'> State:</h3>
                                        <form className='mt-2 w-100 '>
                                            <select id="state" name="state" className='w-100' style={{ height: "50px" }}>
                                                <option value={companyData.state}>{companyData.state}</option>
                                            </select>
                                        </form>
                                    </div>
                                    <div className='d-flex flex-column mb-3 w-100'>
                                        <h3 className='font-size-12 custom-font-600 mt-0 mb-0 text-gray font-sans'>City:</h3>
                                        <form className='mt-2 w-100 mb-1'>
                                            <select id="city" name="city" className='w-100' style={{ height: "50px" }}>
                                                <option value={companyData.city}>{companyData.city}</option>
                                            </select>
                                        </form>
                                    </div>
                                </div>)}
                                <div>
                                    <div className='d-flex flex-column mb-2 w-100 mt-1'>
                                        <p className='font-size-12 custom-font-600  mt-1 mb-0 text-gray no-border w-30 pointer border-5 font-sans' onClick={() => handleResetPassword()}> Reset password:</p>
                                        <input className='new-textinput p-2 mt-2 w-100 mb-1' type="text" value={companyData.temp_password ? companyData.temp_password : null} readOnly />
                                    </div>
                                </div>
                            </div>
                        </Card> */}
            <Card className="w-100 radius-10px custom-border no-shadow">
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <p className="mt-0 mb-0 font-size-20 custom-font-600 text-dark font-sans">
                    Company Details:
                  </p>
                  <div className="d-flex" style={{ gap: "5px" }}>
                    {edit ? (
                      ""
                    ) : (
                      <DriveFileRenameOutlineIcon className="custom-common-theme-text-color" />
                    )}
                    {edit ? (
                      <p
                        className="mt-0 mb-0 font-size-16 custom-font-500 custom-common-theme-text-color pointer"
                        onClick={() => updatecompanydata()}
                      >
                        Update
                      </p>
                    ) : (
                      <p
                        className="mt-0 mb-0 font-size-16 custom-font-500 custom-common-theme-text-color pointer font-sans"
                        onClick={() => setEdit(true)}
                      >
                        Edit
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-3">
                <div className="d-flex flex-column mb-3">
                  <h3 className="font-size-12 custom-font-600 mt-0 mb-0 text-gray font-sans">
                    Organization Name:
                  </h3>
                  {edit ? (
                    <input
                      className="new-textinput p-2 mt-2 w-100"
                      type="text"
                      value={companydata.organisation}
                      onChange={(e) =>
                        setCompanydata({
                          ...companydata,
                          organisation: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <input
                      className="new-textinput p-2 mt-2 w-100 font-sans"
                      placeholder="MyFinalyst"
                      type="text"
                      value={companyData.organisation}
                      readOnly
                    />
                  )}
                </div>
                <div className="d-flex flex-column mb-3">
                  <h3 className="font-size-12 custom-font-600 mt-0 mb-0 text-gray font-sans">
                    Organization Email Id:
                  </h3>
                  {edit ? (
                    <input
                      className="new-textinput p-2 mt-2 w-100"
                      type="text"
                      value={companydata.email}
                      onChange={(e) =>
                        setCompanydata({
                          ...companydata,
                          email: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <input
                      className="new-textinput p-2 mt-2 w-100"
                      placeholder="MyFinalyst@Gmail.com"
                      type="text"
                      value={companyData.email}
                      readOnly
                    />
                  )}
                </div>
                {edit ? (
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <h3 className="font-size-12 custom-font-600 mt-2 mb-0 font-sans text-gray">
                        Select State:
                      </h3>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allstate || []}
                        value={selectedState}
                        onChange={handleStateChange}
                        onInputChange={onInputChange}
                        sx={{
                          width: "100%",
                          backgroundColor: "white",
                          marginTop: 1,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<LocationOnIcon />}
                            inputProps={{
                              ...params.inputProps,
                              style: { border: "none" },
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <h3 className="font-size-12 custom-font-600 mt-2 mb-0 text-gray font-sans">
                        Select City:
                      </h3>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allcities || []}
                        value={selectedCity}
                        onChange={handleCityChange}
                        onInputChange={onInputChangecity}
                        sx={{
                          width: "100%",
                          backgroundColor: "white",
                          marginTop: 1,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={<LocationOnIcon />}
                            inputProps={{
                              ...params.inputProps,
                              style: { border: "none" },
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row mt-1">
                    <div className="col-md-6 mb-3">
                      <h3 className="font-size-12 custom-font-600 mt-0 mb-0 text-gray font-sans">
                        State:
                      </h3>
                      <form className="mt-2 w-100">
                        <select
                          id="state"
                          name="state"
                          className="w-100"
                          style={{
                            height: "50px",
                            borderRadius: "10px",
                            backgroundColor: "#F8F8F8",
                            borderColor: isFocused ? "#D6D6D6" : "#D6D6D6",
                          }}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                        >
                          <option value={companyData.state}>
                            {companyData.state}
                          </option>
                        </select>
                      </form>
                    </div>
                    <div className="col-md-6 mb-3">
                      <h3 className="font-size-12 custom-font-600 mt-0 mb-0 text-gray font-sans">
                        City:
                      </h3>
                      {/* <form className="mt-2 w-100">
                        <select
                          id="city"
                          name="city"
                          className="w-100"
                          style={{ height: "50px",borderRadius: "10px"  }}
                        >
                          <option value={companyData.city}>
                            {companyData.city}
                          </option>
                        </select>
                      </form> */}
                      <form className="mt-2 w-100">
                        <select
                          id="state"
                          name="state"
                          className="w-100"
                          style={{
                            height: "50px",
                            borderRadius: "10px",
                            backgroundColor: "#F8F8F8",
                            borderColor: isFocused ? "#D6D6D6" : "#D6D6D6",
                          }}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                        >
                          <option value={companyData.city}>
                            {companyData.city}
                          </option>
                        </select>
                      </form>
                    </div>
                  </div>
                )}
                {edit ? <div className="d-flex flex-column mb-2 w-100 mt-1">
                  <p
                    className="font-size-12 custom-font-600 mt-1 mb-0 text-gray no-border w-30 pointer border-5 font-sans d-flex align-items-center"
                    onClick={() => handleResetPassword()}
                  >
                    <span><LockResetIcon /></span><span>Password:</span>
                  </p>
                  <input
                    className="new-textinput p-2 mt-2 w-100 mb-1"
                    type="text"
                    value={
                      companyData.temp_password
                        ? companyData.temp_password
                        : "For reset click on Password!"
                    }
                    readOnly
                  />
                </div> : <div className="d-flex flex-column mb-2 w-100 mt-1">
                  <p
                    className="font-size-12 custom-font-600 mt-1 mb-0 text-gray no-border w-30  border-5 font-sans"
                  >
                    Password:
                  </p>
                  <input
                    className="new-textinput p-2 mt-2 w-100 mb-1"
                    type="text"
                    value={
                      companyData.temp_password
                        ? companyData.temp_password
                        : "For reset password click on Edit!"
                    }
                    readOnly
                  />
                </div>}
              </div>
            </Card>
          </div>
          <div className="col-sm-8">
            <Card className="w-100 radius-10px custom-border no-shadow">
              <div className="p-3">
                <div>
                  <p className="mt-0 mb-0 font-size-20 custom-font-600 text-dark font-sans">
                    Overview:
                  </p>
                </div>
                <div className="d-flex " style={{ gap: "15px" }}>
                  <Card
                    className="w-100 radius-10px no-shadow gray-border"
                    style={{ backgroundColor: "#F8F8F8" }}
                  >
                    <div className="p-3">
                      <div className="custom-cercle d-flex justify-content-center align-items-center">
                        <OverviewSVGComponent2
                          height={25}
                          width={26}
                          color={"black"}
                        />
                      </div>
                      <div className="p-2">
                        <p className="mt-0 mb-0 font-size-24 custom-font-600">
                          16
                        </p>
                        <p className="mt-0 mb-0 font-size-16 custom-font-500 text-secondary ml-2 font-sans">
                          {" "}
                          Stats 1
                        </p>
                      </div>
                    </div>
                  </Card>
                  <Card
                    className="w-100 radius-10px no-shadow gray-border"
                    style={{ backgroundColor: "#F8F8F8" }}
                  >
                    <div className="p-3">
                      <div className="custom-cercle d-flex justify-content-center align-items-center">
                        <OverviewSVGComponent2
                          height={25}
                          width={26}
                          color={"black"}
                        />
                      </div>
                      <div className="p-2">
                        <p className="mt-0 mb-0 font-size-24 custom-font-600 font-sans">
                          16
                        </p>
                        <p className="mt-0 mb-0 font-size-16 custom-font-500 text-secondary ml-2 font-sans">
                          {" "}
                          Stats 2
                        </p>
                      </div>
                    </div>
                  </Card>
                </div>
                {/*  */}
                <div className="d-flex mt-3" style={{ gap: "15px" }}>
                  <Card
                    className="w-100 radius-10px no-shadow gray-border"
                    style={{ backgroundColor: "#F8F8F8" }}
                  >
                    <div className="p-3">
                      <div className="custom-cercle d-flex justify-content-center align-items-center">
                        <OverviewSVGComponent2
                          height={25}
                          width={26}
                          color={"black"}
                        />
                      </div>
                      <div className="p-2">
                        <p className="mt-0 mb-0 font-size-24 custom-font-600 font-sans">
                          16
                        </p>
                        <p className="mt-0 mb-0 font-size-16 custom-font-500 text-secondary ml-2 font-sans">
                          {" "}
                          Stats 3
                        </p>
                      </div>
                    </div>
                  </Card>
                  <Card
                    className="w-100 radius-10px no-shadow gray-border"
                    style={{ backgroundColor: "#F8F8F8" }}
                  >
                    <div className="p-3">
                      <div className="custom-cercle d-flex justify-content-center align-items-center">
                        <OverviewSVGComponent2
                          height={25}
                          width={26}
                          color={"black"}
                        />
                      </div>
                      <div className="p-2">
                        <p className="mt-0 mb-0 font-size-24 custom-font-600 font-sans">
                          16
                        </p>
                        <p className="mt-0 mb-0 font-size-16 custom-font-500 text-secondary ml-2 font-sans">
                          {" "}
                          Stats 4
                        </p>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <p className="mt-0 mb-0 custom-font-600 font-size-20 font-sans">
            Subscription Details:
          </p>
          <Button
            variant="outlined"
            className="primary-border custom-common-theme-text-color custom-font-600 font-size-16 text-capitalize font-sans"
            onClick={handleOpened}
            style={{ width: "170px", height: "45px" }}
          >
            Add Subscription
          </Button>

          <Modal
            open={opens}
            onClose={handleClosed}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <SelectPlanModal
              handleClosed={handleClosed}
              plans={plans}
              getsubscriptions={getsubscriptions}
            />
          </Modal>
        </div>

        {/* table */}
        <div className="mb-2">
          <div>
            <div style={{ overflowY: "auto" }}>
              <table className="mt-4 tablesupport">
                <thead style={{ backgroundColor: "#F8F8F8" }}>
                  <tr>
                    <th
                      className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                      style={{ width: "10%" }}
                    >
                      Invoice
                    </th>
                    <th
                      className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                      style={{ width: "10%" }}
                    >
                      Subscription Type
                    </th>
                    <th
                      className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                      style={{ width: "10%" }}
                    >
                      Price
                    </th>
                    <th
                      className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                      style={{ width: "10%" }}
                    >
                      Start Date
                    </th>
                    <th
                      className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                      style={{ width: "10%" }}
                    >
                      End Date
                    </th>
                    <th
                      className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                      style={{ width: "16%" }}
                    >
                      Payment Mode
                    </th>
                    <th
                      className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                      style={{ width: "10%" }}
                    >
                      {" "}
                      Status
                    </th>
                    <th
                      className="text-left text-uppercase text-gray  font-size-12 custom-font-600 font-inter"
                      style={{ width: "10%" }}
                    >
                      {" "}
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptions.length > 0 ? (
                    subscriptions.map((det) => {
                      return (
                        <tr className="bg-white">
                          <td className="text-left">
                            <DownloadIcon
                              height={25}
                              width={26}
                              color={"#03565A"}
                            />
                          </td>
                          <td className="text-left font-size-16 custom-font-500 text-gray font-sans">
                            {det.plan_name}
                          </td>
                          <td className="text-left font-size-16 custom-font-500 text-gray font-sans">
                            {det.plan_price}
                          </td>
                          <td className="text-left font-size-16 custom-font-500 text-gray font-sans">
                            {moment(det.subscription_start_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td className="text-left font-size-16 custom-font-500 text-gray font-sans">
                            {moment(det.subscription_end_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td className="text-left font-size-16 custom-font-500 text-gray font-sans">
                            Card
                          </td>
                          <td className="text-left font-size-16 custom-font-500 text-gray font-sans">
                            {det.status}
                          </td>
                          <td className="text-left">
                            <MoreVertIcon />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <p
                        className="mt-0 mb-0 text-gray font-size-12"
                        style={{ marginLeft: "10px" }}
                      >
                        This Company don't have any subscription plan
                      </p>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySpecific;
