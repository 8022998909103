import React, { useState, useEffect } from "react";
import { Button, Popover, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import TranglePopover from "./TranglePopover"; // Assuming TranglePopover.js is in the same directory

import {
  getTeam,
  deleteTeam,
  updateTeam,
  postTeam,
} from "../../../apihandler/apihandler";
import Swal from "sweetalert2";
import nodata from "../../../Assets/no data.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import SuperAdminModal from "./SuperAdminModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const TeamMenuBarTable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [allteam, setAllTeam] = useState([]);
  const [singleElement, setSingleElement] = useState({});
  const [editAction, setEditAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [postData, setPostData] = useState({
    name: "",
    email: "",
    password: "",
  });

  // post Team data------------
  const handleCreateTeam = () => {
    handleCloseModal();

    postTeam(postData)
      .then((res) => {
        setPostData({ name: "", email: "", password: "" });
        handleCloseModal();
        getTeamModule();
        Swal.fire({
          title: "Success!",
          text: "Team created successfully.",
          icon: "success",
          confirmButtonColor: "#03565A",
        });
      })
      .catch((error) => {
        console.error("Error creating Team module:", error);
        handleCloseModal();
        Swal.fire(
          "Error",
          "Failed to create Team. Please try again later.",
          "error"
        );
      });
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPostData({ ...postData, [name]: value });
  };

  useEffect(() => {
    getTeamModule();
  }, []);

  // get api call-------------
  const getTeamModule = async () => {
    try {
      const res = await getTeam();
      if (res.status === 200) {
        setAllTeam(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching team:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const handleEdit = () => {
    handleClose();
    setPostData({
      ...postData,
      name: singleElement.name,
      email: singleElement.email,
      password: singleElement.password,
    });
    setEditAction("edit");
    handleOpenModal();
  };

  const UpdateTeamModule = () => {
    handleCloseModal();

    updateTeam(singleElement._id, postData)
      .then((res) => {
        setPostData({ ...postData, name: "", email: "", password: "" });
        setEditAction("create");
        getTeamModule();
        Swal.fire({
          title: "Success!!",
          text: "Team  updated successfully.",
          icon: "Success!",
          confirmButtonColor: "#03565A",
        });
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error updating Team:", error);
        Swal.fire(
          "Error",
          "Failed to update Team. Please try again later.",
          "error"
        );
      });
  };

  const handleClick = (event, element) => {
    setSingleElement(element);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // delete api------------------
  const deleteTeamData = async () => {
    handleClose();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#03565A",
      cancelButtonColor: "#03565A",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      const res = await deleteTeam(singleElement._id);
      if (res.status === 200) {
        Swal.fire({
          title: "Deleted!",
          text: "Your Team has been deleted.",
          icon: "success",
          confirmButtonColor: "#03565A",
        });
        getTeamModule();
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filteredCompanies = allteam.filter(company => 
    company?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.email.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // // popover
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  // return (

  //   <>

  //     {
  //       loading ? (<div className='d-flex justify-content-center align-items-center vh-100'>
  //         <CircularProgress />
  //       </div>)
  //         :
  //         allteam.length === 0 ? (
  //           <div className="d-flex flex-column justify-content-center align-items-center vh-70">
  //             <img src={nodata} className="img-fluid" style={{ maxWidth: "300px" }} alt="No Data" />
  //             <p className='text-secondary fs-4'>You have no team data</p>

  //             <Modal
  //               open={openModal}
  //               onClose={handleCloseModal}
  //               aria-labelledby="child-modal-title"
  //               aria-describedby="child-modal-description"
  //             >
  //               <Box sx={{ ...style, width: 500 }}>
  //                 <div>
  //                   <label style={{ fontSize: "20px" }}>Name</label>
  //                   <br />
  //                   <TextField
  //                     name="name"
  //                     onChange={handleChange}
  //                     value={postData.name}
  //                     sx={{
  //                       width: 500,
  //                       maxWidth: '100%',
  //                       marginTop: "10px"
  //                     }}
  //                   />
  //                 </div>

  //                 <div>
  //                   <label style={{ fontSize: "20px" }}>Email</label>
  //                   <br />
  //                   <TextField
  //                     name="email"
  //                     onChange={handleChange}
  //                     value={postData.email}
  //                     sx={{
  //                       width: 500,
  //                       maxWidth: '100%',
  //                       marginTop: "10px"
  //                     }}
  //                   />
  //                 </div>

  //                 <div>
  //                   <label style={{ fontSize: "20px" }}>password</label>
  //                   <br />
  //                   <TextField
  //                     name="password"
  //                     onChange={handleChange}
  //                     value={postData.password}
  //                     sx={{
  //                       width: 500,
  //                       maxWidth: '100%',
  //                       marginTop: "10px"
  //                     }}
  //                   />
  //                 </div>

  //                 <div style={{ display: "flex", gap: "20px", marginLeft: "65%", paddingTop: "30px" }}>
  //                   <Button style={{ backgroundColor: "rgb(3, 86, 90)", color: "white" }} onClick={() => setOpenModal(false)}>CANCEL</Button>
  //                   <Button style={{ backgroundColor: "rgb(3, 86, 90)", color: "white" }}
  //                     onClick={() => {
  //                       if (editAction === "edit") {
  //                         UpdateTeamModule();
  //                       } else {
  //                         handleCreateTeam();
  //                       }
  //                     }}
  //                   >{editAction === "edit" ? "Update" : "Create"}</Button>
  //                 </div>
  //               </Box>
  //             </Modal>

  //             {/* Displaying Team modules */}
  //             <div style={{ display: 'flex', justifyContent: 'space-between', padding: '30px' }}>
  //               <Button style={{
  //                 backgroundColor: "#03565A",
  //                 color: "white",
  //                 padding: "2px 90px",
  //                 fontSize: "1rem"
  //               }} onClick={handleOpenModal}>Add New Team </Button>
  //             </div>

  //           </div>

  //         ) : (

  //           <div>

  //             <Modal
  //               open={openModal}
  //               onClose={handleCloseModal}
  //               aria-labelledby="child-modal-title"
  //               aria-describedby="child-modal-description"
  //             >
  //               <Box sx={{ ...style, width: 500 }}>
  //                 <div>
  //                   <label style={{ fontSize: "20px" }}>Name</label>
  //                   <br />
  //                   <TextField
  //                     name="name"
  //                     onChange={handleChange}
  //                     value={postData.name}
  //                     sx={{
  //                       width: 500,
  //                       maxWidth: '100%',
  //                       marginTop: "10px"
  //                     }}
  //                   />
  //                 </div>

  //                 <div>
  //                   <label style={{ fontSize: "20px" }}>Email</label>
  //                   <br />
  //                   <TextField
  //                     name="email"
  //                     onChange={handleChange}
  //                     value={postData.email}
  //                     sx={{
  //                       width: 500,
  //                       maxWidth: '100%',
  //                       marginTop: "10px"
  //                     }}
  //                   />
  //                 </div>

  //                 <div>
  //                   <label style={{ fontSize: "20px" }}>password</label>
  //                   <br />
  //                   <TextField
  //                     name="password"
  //                     onChange={handleChange}
  //                     value={postData.password}
  //                     sx={{
  //                       width: 500,
  //                       maxWidth: '100%',
  //                       marginTop: "10px"
  //                     }}
  //                   />
  //                 </div>

  //                 <div style={{ display: "flex", gap: "20px", marginLeft: "65%", paddingTop: "30px" }}>
  //                   <Button style={{ backgroundColor: "rgb(3, 86, 90)", color: "white" }} onClick={() => setOpenModal(false)}>CANCEL</Button>
  //                   <Button style={{ backgroundColor: "rgb(3, 86, 90)", color: "white" }}
  //                     onClick={() => {
  //                       if (editAction === "edit") {
  //                         UpdateTeamModule();
  //                       } else {
  //                         handleCreateTeam();
  //                       }
  //                     }}
  //                   >{editAction === "edit" ? "Update" : "Create"}</Button>
  //                 </div>
  //               </Box>
  //             </Modal>

  //             {/* Displaying Team modules */}
  //             <div style={{ display: 'flex', justifyContent: 'space-between', padding: '30px' }}>
  //               <Typography variant='h5'>All Team Members</Typography>
  //               <Button style={{ backgroundColor: "rgb(3, 86, 90)", color: "white" }} onClick={handleOpenModal}>Add New Team </Button>
  //             </div>
  //             <div style={{ padding: '30px' }}>
  //               <TableContainer component={Paper}>
  //                 <Table>
  //                   <TableHead sx={{ backgroundColor: '#DDDDDD79' }}>
  //                     <TableRow>

  //                       <TableCell align="center">Name</TableCell>
  //                       <TableCell align="center">Email</TableCell>
  //                       <TableCell align="center">Action</TableCell>
  //                     </TableRow>
  //                   </TableHead>
  //                   <TableBody>
  //                     {allteam?.map((det) => {
  //                       return (
  //                         <TableRow key={det._id}>
  //                           <TableCell align="center">{det.name}</TableCell>
  //                           <TableCell align="center">{det.email}</TableCell>

  //                           <TableCell align="center">
  //                             <MoreVertIcon onClick={(e) => handleClick(e, det)} />
  //                             <Popover
  //                               id={id}
  //                               open={open}
  //                               anchorEl={anchorEl}
  //                               onClose={handleClose}
  //                               anchorOrigin={{
  //                                 vertical: "bottom",
  //                                 horizontal: "center",
  //                               }}
  //                             >
  //                               <Typography sx={{ p: 2 }}>
  //                                 {singleElement.isadmin === "yes" ? (
  //                                   <span style={{ cursor: "pointer" }} onClick={() => handleEdit(det)}>Edit</span>
  //                                 ) : (
  //                                   <>
  //                                     <span style={{ cursor: "pointer" }} onClick={() => handleEdit(det)}>Edit</span> <br />
  //                                     <span style={{ cursor: "pointer", marginLeft: "8px" }} onClick={() => deleteTeamData(det)}>Delete</span>
  //                                   </>
  //                                 )}
  //                               </Typography>
  //                             </Popover>
  //                           </TableCell>
  //                         </TableRow>
  //                       )
  //                     })}
  //                   </TableBody>
  //                 </Table>
  //               </TableContainer>
  //             </div>
  //           </div>

  //         )
  //     }

  //   </>
  // );
  return (
    <>
      <div className="px-4 py-3">
        <div className="d-flex justify-content-between mt-3">
          <div>
            <p className="mt-0 mb-0 custom-font-600 font-size-20 font-sans">
              Super Admin
            </p>
          </div>
          <div className="d-flex" style={{ gap: "10px" }}>
            <div className="search-btnv2 d-flex flex-row jusify-content-center align-items-center">
              <span>
                <SearchIcon style={{ color: "gray" }} />
              </span>
              <input
                style={{ border: "none" }}
                className="w-100"
                placeholder="Search by Company name or ID..."
                value={searchTerm}
                onChange={(e)=>setSearchTerm(e.target.value)}
              ></input>
            </div>
            <div>
              <Button
                variant="outlined"
                className="font-size-16 custom-font-600 primary-border custom-common-theme-text-color text-capitalize font-sans radius-6px"
                style={{ height: "48px" }}
                onClick={handleOpenModal}
              >
                Add Super Admin
              </Button>

              <SuperAdminModal
                open={openModal}
                handleClose={handleCloseModal}
                getTeam={getTeamModule}
              />
            </div>
          </div>
        </div>

        {/* table */}


        <div style={{ overflowY: "auto" }}>
          <table className="mt-4 tablesupport">
            <thead style={{ backgroundColor: "#F8F8F8" }}>
              <tr>
                <th
                  className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter"
                  style={{ width: "20%" }}
                >
                  Sn.No
                </th>
                <th
                  className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter"
                  style={{ width: "20%" }}
                >
                  Name
                </th>
                <th
                  className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter"
                  style={{ width: "20%" }}
                >
                  Email
                </th>
                {/* <th
                  className="text-left text-uppercase text-secondary font-size-12 custom-font-600 font-inter"
                  style={{ width: "20%" }}
                >
                  Responsibilities
                </th> */}
                <th
                  className="text-center text-uppercase text-secondary font-size-12 custom-font-600 font-inter"
                  style={{ width: "20%" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {
                filteredCompanies.map((team, index) => {
                  return (
                    <tr>
                      <td className="text-right font-size-14 custom-font-500 text-gray font-sans">
                        {index + 1}
                      </td>
                      <td className="text-left  font-size-14 custom-font-500 text-gray font-sans">
                        {team.name}
                      </td>
                      <td className="text-left font-size-14 custom-font-500 text-gray font-sans">
                        {team.email}
                      </td>
                      {/* <td className="text-left font-size-14 custom-font-500 text-gray font-sans">
                  Preparer
                </td> */}
                      <td className="text-center font-size-14 custom-font-500 text-gray font-sans">
                        <MoreVertIcon aria-describedby={id} onClick={(e)=>handleClick(e,team)} />

                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center", // Corrected "cenetr" to "center"
                            marginLeft: "30px",
                          }}
                          PaperProps={{
                            style: {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                              // borderRadius: "8px", // Border radius added
                              // border: "2px solid red", // Border added with red color
                              marginLeft: "-15px",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              mt: "8px",
                              "&::before": {
                                backgroundColor: "white",
                                content: '""',
                                display: "block",
                                position: "absolute",
                                width: 12,
                                height: 12,
                                top: -6,
                                transform: "rotate(45deg)",
                                left: "calc(50% - 6px)",
                              },
                            }}
                          />
                          <Typography sx={{ p: 1, backgroundColor: "white" }}>
                            <p className="mt-0 mb-0 font-size-12 custom-font-600 text-gray font-sans" >
                              Edit
                            </p>
                            <p className="mt-0 mb-0 font-size-12 custom-font-600 text-gray font-sans pointer" onClick={()=>deleteTeamData(team)}>
                              Delete
                            </p>
                          </Typography>
                        </Popover>
                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TeamMenuBarTable;
