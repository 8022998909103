import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Button } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef } from 'react';
import logo from './signin.png'
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { getInvoiceById, getAlluserById, getsubScriptions } from '../../../apihandler/apihandler';
import axios from 'axios';

const PdfDownload = () => {

    const [invoices, setInvoices] = useState([])
    const [companydata, setCompanyData] = useState('')
    const [total, setTotal] = useState('');
    const [details, setDetails] = useState('');
    const pdf = useRef();
    const { id } = useParams();
    console.log(id, 'id---')

    const currentDate = moment().format('MMMM Do YYYY');

    const getInvoice = async () => {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/superadmin/get-invoices/${id}`)
        console.log(res, 'resid---')
        if (res.status === 200) {
            setInvoices(res.data.data)
        } else {
            console.log('error')
        }

    }



    const fetchCompany = async (id) => {
        try {
            const response = await getAlluserById(id);
            console.log("Fetched user data:", response);
            if (response.status === 200) {
                console.log(response.data.user, 'user--------------------')
                setCompanyData(response.data.user);
            }
        } catch (error) {
            console.error('Error fetching company details:', error);
        }
    };


    const getDetails = async (id) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}api/v1/payment/get-active-plan`, {
                headers: {
                    'customer_id': id
                }
            })
            console.log(res, 'all subscriptions -------->');
            setDetails(res.data);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
        }
    }

    useEffect(() => {
        getInvoice();
    }, []);

    useEffect(() => {
        if (invoices && Object.keys(invoices).length > 0) {
            fetchCompany(invoices.customer_id);
            getDetails(invoices.customer_id);
        }
    }, [invoices]);




    const downloadInvoice = () => {
        const input = pdf.current;
        console.log(input, 'input--pdf');
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a5', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = (pdfHeight - imgHeight * ratio) / 2;
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
            pdf.save('invoice-pdf')
        })


    }



    return (
        <>
            <Button onClick={() => downloadInvoice()} style={{ marginTop: '10px', backgroundColor: '#03565A', color: 'white', float: 'right', marginRight: "10px" }}>Download</Button>
            <div style={{ margin: '10px 300px' }} ref={pdf}>
                <div style={{ padding: '30px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img src={logo} style={{ width: '130px', height: '50px' }}></img>
                        <h4 style={{ marginRight: '10px' }}>Invoice</h4>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                        <div>
                            <p style={{ margin: '0px' }}>Hello, <span style={{ color: "red" }}>{companydata.organisation}</span></p>
                            <p>Thank you for shopping from our store<br></br> and for your order.</p>

                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <p style={{ margin: '0px', fontSize: 'small' }}>ORDER #800000025</p>
                            <p style={{ fontSize: 'small', textAlign: 'end' }}>{currentDate}</p>

                        </div>
                    </div>
                    <div>
                        <Table aria-label="simple table">
                            <TableHead >
                                <TableRow>
                                    <TableCell style={{ paddingLeft: '0px' }}>Item</TableCell>
                                    <TableCell style={{ paddingLeft: '100px', textAlign: 'start' }}>SKU</TableCell>
                                    <TableCell style={{ paddingLeft: '170px' }}>Quantity</TableCell>
                                    <TableCell style={{ paddingLeft: '50px', textAlign: 'end' }}>Subtotal</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow style={{ marginLeft: "0px" }}>
                                    <TableCell component="th" scope="row" style={{ paddingLeft: '0px', color: 'red' }}>{invoices.plan_name}</TableCell>
                                    <TableCell style={{ paddingLeft: '80px', textAlign: 'center' }}>{invoices.invoice_no}</TableCell>
                                    <TableCell style={{ textAlign: 'center', paddingLeft: '150px' }}>1</TableCell>
                                    <TableCell style={{ textAlign: 'end', paddingLeft: '100px' }}>{invoices.invoice_subtotal}.00</TableCell>

                                </TableRow>

                            </TableBody>
                        </Table>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                        <div style={{ display: 'flex', gap: '50px' }}>
                            <div style={{ textAlign: 'end' }}>
                                <p>Subtotal</p>
                                <p>Shipping & Handling</p>
                                <p>Grand Total (Incl.Tax)</p>
                                <p>TAX</p>
                            </div>
                            <div style={{ textAlign: 'end', marginRight: '10px' }}>
                                <p>{invoices ? <span>{invoices.invoice_subtotal}.00</span> : '00.0'}</p>
                                <p>15.00</p>
                                <p>{invoices ? <span>{invoices.invoice_subtotal + 15}.00</span> : '00.0'}</p>
                                <p>0</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: "200px", marginTop: '50px' }}>
                        <div>
                            <h6>BILLING INFORMATION</h6>
                            <p style={{ margin: '0px', padding: '2px' }}>{details?.billingDetails?.firstname}</p>
                            <p style={{ margin: '0px', padding: '2px' }}>{details?.billingDetails?.aprtment},{details?.billingDetails?.address}</p>
                            <p style={{ margin: '0px', padding: '2px' }}>{details?.billingDetails?.city},{details?.billingDetails?.country}</p>
                            <p style={{ margin: '0px', padding: '2px' }}>T: 202-555-0133</p>
                        </div>
                        <div>
                            <h6>PAYMENT METHOD</h6>
                            <p style={{ margin: '0px', padding: '2px' }}>Credit Card</p>
                            <p style={{ margin: '0px', padding: '2px' }}>Payment via : {details?.payment_via}</p>
                            <p style={{ margin: '0px', padding: '2px' }}>Payment Id:{details?.razorpay_payment_id}</p>
                            <p style={{ margin: '0px', padding: '2px' }}>T: 202-555-0133</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", gap: '200px', marginTop: '50px' }}>
                        <div>
                            <h6>SHIPPING INFORMATION</h6>
                            <p style={{ margin: '0px', padding: '2px' }}>{details?.billingDetails?.firstname}</p>
                            <p style={{ margin: '0px', padding: '2px' }}>{details?.billingDetails?.aprtment},{details?.billingDetails?.address}</p>
                            <p style={{ margin: '0px', padding: '2px' }}>{details?.billingDetails?.city},{details?.billingDetails?.country}</p>
                            <p style={{ margin: '0px', padding: '2px' }}>T: 202-555-0133</p>
                        </div>
                        <div>
                            <h6>SHIPPING METHOD</h6>
                            <p style={{ margin: '0px', padding: '2px' }}>UPS: U.S. Shipping Services</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '50px', fontSize: 'small' }}>
                        <span style={{ color: 'blue' }}>Have a nice day.</span>
                    </div>
                </div>

            </div>
        </>

    )
}

export default PdfDownload