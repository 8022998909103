import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import Card from '@mui/material/Card';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Modal } from '@mui/material';
// import SelectcustomModal from './SelectcustomModal';
import CreatePlanModal from './CreatePlanModal';
import PaymentModal from './PaymentModal';
// import getAllPlans from "../../../apihandler/apihandler"



const SelectPlanModal = ({ plans, handleClosed, getsubscriptions, handlePlanActivation }) => {
  const [selectedValue, setSelectedValue] = useState(''); // Set Silver as the default value
  const [selectedPlan, setSelectedPlan] = useState(null);


  console.log(plans, "-----plNS-")

  // useEffect(() => {
  //     // Check if the user has already selected a plan
  //     const storedPlanId = localStorage.getItem('selectedPlanId');
  //     if (storedPlanId) {
  //         setSelectedValue(storedPlanId);
  //         const storedPlan = plans.find(plan => plan._id === storedPlanId);
  //         setSelectedPlan(storedPlan);
  //     }
  // }, []);

  const handleChange = (event) => {
    const selectedId = event.target.value
    setSelectedValue(selectedId)
    const selectedPlan = plans.find(plan => plan._id === selectedId);
    setSelectedPlan(selectedPlan);
    // localStorage.setItem('selectedPlanId', selectedId);
  };
  // 
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  };

  console.log(selectedValue, "selectedValue----")


  const handleClose = () => setOpen(false);

  // const planhandler = async () => {
  //     try {
  //       const res = await getAllPlans();
  //       if (res.status === 200) {
  //         console.log(res,"response>>>>>>")
  //         setPlans(res.data.data)
  //       }

  //     } catch (error) {
  //       console.log(error)
  //     }
  //     // finally {
  //     //     setLoading(false); // Set loading to false regardless of success or failure
  //     // }
  //   }


  const getCardStyles = (planId) => ({
    backgroundColor: selectedValue === planId ? '#03565A33' : '#F8F8F8',
    borderColor: selectedValue === planId ? '#03565A' : '#D6D6D6',
    borderWidth: '1px', // Always set the border width to 1px
    borderStyle: 'solid', // Always set the border style to solid
  });

  // Function to get styles for text elements including the label
  const getTextStyles = (planId) => ({
    color: selectedValue === planId ? '#000000' : '#717171',
    fontWeight: selectedValue === planId ? '500' : 'normal',
    whiteSpace: 'nowrap',

  });


  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '10px',
      }}
    >
      <div>
        <div className='d-flex justify-content-between p-4 top-redious' style={{ backgroundColor: "#F1F2F5", height: "75px" }}>
          <p></p>
          <p className='mt-0 mb-0 custom-font-600 font-size-20 text-dark font-sans'>Select Your Plan</p>
          <h6 className='mt-0 mb-0 text-dark pointer font-sans font-size-16 custom-font-600 font-sans' onClick={() => handleClosed()}>Close</h6>
        </div>
        {/* <div className='d-flex px-4 py-4' style={{ gap: '15px',overflowX:"auto" }}>
                   {plans.map((elem)=>{
                    return(
                    <Card className='w-100 radius-10px' style={{ ...getCardStyles(elem._id), height: '400px' , border:"1px solid #D6D6D6"}} key={elem._id}>
                        <div className='px-3 mt-2'>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={selectedValue}
                                name="radio-buttons-group"
                                onChange={handleChange}
                            >
                                <FormControlLabel value={elem._id} control={<Radio />} label={elem.planName} className="custom-font-500 font-size-16 font-sans color-#03565A" />
                            </RadioGroup>
                            <h4 className='font-size-20 custom-font-600 text-dark font-sans'>₹{elem.planPrice}</h4>
                            
                            <div className='d-flex mt-1' style={{ gap:"5px" }}>
                            <i className="bi bi-check-circle" style={{ width: "15px", height: "15px" }}></i>
                            <p className='mt-0 mb-0  font-sans custom-font-500 font-size-14 mt-1 font-sans'>{elem.users} Users</p>
                            </div>
                            <div className='d-flex mt-1' style={{ gap:"5px" }}>
                            <i className="bi bi-check-circle" style={{ width: "15px", height: "15px" }}></i>
                            <p className='mt-0 mb-0  font-sans custom-font-500 font-size-14 mt-1 font-sans'>{elem.planDescription}</p>
                            </div>
                            <div className='d-flex mt-1' style={{ gap:"5px" }}>
                            <i className="bi bi-check-circle" style={{ width: "15px", height: "15px" }}></i>
                            <p className='mt-0 mb-0  font-sans custom-font-500 font-size-14 mt-1 font-sans'>Book Closure, Standalone <br /> financial statements.</p>
                            </div>
                        </div>
                    </Card>

                    )
                   })}
                  
                </div> */}
        <div className='container w-100'>
          <div className='d-flex px-4 py-4' style={{ gap: '15px', overflowX: plans.length > 4 ? 'auto' : 'hidden' }}>
            {plans.map((elem) => (
              <Card
                className='w-100 radius-10px no-shadow gray-border'
                style={{ ...getCardStyles(elem._id), height: '400px', minWidth: '260px' }}
                key={elem._id}
              >
                <div className='px-3 mt-2'>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedValue}
                    name="radio-buttons-group"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={elem._id}
                      control={<Radio />}
                      label={elem.planName}
                      className="custom-font-500 font-size-16 font-sans"
                      style={getTextStyles(elem._id)}
                    />
                  </RadioGroup>
                  <h4 className='font-size-20 custom-font-600 font-sans' style={getTextStyles(elem._id)}>₹ {elem.planPrice}</h4>
                  <div className='d-flex mt-3' style={{ gap: '5px' }}>
                    <i className="bi bi-check-circle"></i>
                    <p className='mt-0 mb-0 font-sans font-size-14 ' style={{ ...getTextStyles(elem._id), whiteSpace: 'pre-line' }}>{elem.categorizedBenefits.noOfUsers[0]} Users</p>
                  </div>
                  <div className='d-flex mt-2' style={{ gap: '5px' }}>
                    <i className="bi bi-check-circle" ></i>
                    <p className='mt-0 mb-0 font-sans font-size-14' style={{ ...getTextStyles(elem._id), whiteSpace: 'pre-line' }}>{elem.planDescription}</p>
                  </div>
                  <div className='d-flex mt-2 ' style={{ gap: '5px'}}>
                    <i className="bi bi-check-circle" ></i>
                    <p className='mt-0 mb-0 font-sans font-size-14 ' style={{ ...getTextStyles(elem._id), whiteSpace: 'pre-line' }}>{elem.benefits[0]}</p>
                  </div>
                </div>
              </Card>
            ))}
          </div>

        </div>
        <div className=' d-flex justify-content-end mb-3 container mt-2'>
          <Button variant="contained" className='text-capitalize radius-6px custom-common-theme-bg-color custom-font-600 font-size-16 text-white font-sans  ' style={{ width: "180px", height: "50px" }} onClick={handleOpen} disabled={!selectedPlan}>Next</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            {/* <CreatePlanModal handleClose={handleClose} /> */}
            <PaymentModal handleClosed={handleClosed} handleClose={handleClose} selectedPlan={selectedPlan} getsubscriptions={getsubscriptions} handlePlanActivation={handlePlanActivation} />
          </Modal>
        </div>
      </div>
    </Box>
  );
}

export default SelectPlanModal;
