import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Dot,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    
    pv: 65,
   
  },
  {
   
    pv: 50,
    
  },
  {
    
    pv: 84,
    
  },
  {
    
    pv: 87,
    
  },
  {
    pv: 43,
  },
  {
    pv: 59,
  },
  {
    pv: 35,
  }
];

export default function ChartOne() {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dataItem = payload[0].payload;
      return (
        <div style={{ backgroundColor: 'black', padding: '5px', color: 'white' }}>
          <p>{`Value: ${dataItem.pv}`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomDot = (props) => {
    const { cx, cy } = props;

    return (
      <Dot
        {...props}
        cx={cx}
        cy={cy}
        fill="none" // Customize the color of the dots
        stroke="white" // Customize the border color of the dots
        r={4} // Set the radius of the dots
      />
    );
  };

  return (
    <ResponsiveContainer width="99%" height="45%">
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom:5
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        {/* <XAxis dataKey="name" /> */}
        {/* <YAxis /> */}
        <Tooltip content={<CustomTooltip/>}/>
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#8884d8"
          activeDot={{ r: 4 }}
          dot={<CustomDot/>}
        />
        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
      </LineChart>
    </ResponsiveContainer>
  );
}
